define('fcs-dashboard/pods/components/menu/menu-wizard/wizard-base/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component', 'fcs-dashboard/mixins/common-mixin'], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsCommonMixin) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var Object = _ember['default'].Object;
  var A = _ember['default'].A;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], _fcsDashboardMixinsCommonMixin['default'], {
    productTypeTaxNotSelectedCount: '0',
    productGroupTypeTaxNotSelectedCount: '0',
    productTypeTaxNotSelected: A(),
    productGroupTypeTaxNotSelected: A(),
    showProductTypeTaxNotSelectedModal: false,

    productTypeTaxNotSetCount: '0',
    productGroupTypeTaxNotSetCount: '0',
    productTypeTaxNotSet: A(),
    productGroupTypeTaxNotSet: A(),
    showProductTypeTaxNotSetModal: false,

    showProductTypeSelector: false,
    showModal: false,
    stepName: computed.alias('currentStep.label'),
    currentStep: null,
    wizardComponentName: 'menu/menu-wizard/menu-information',
    nextStepButtonState: true,
    previousStepButtonState: false,
    isWizardComplete: computed('menu.isNew', function () {
      return !this.get('menu.isNew');
    }),
    activeBreadcrumbs: computed('menu', function () {
      return !this.get('menu.isNew');
    }),
    crumbsList: computed(function () {
      var step1 = Object.create({
        label: 'menu.builder.steps.menuInfo.title',
        isActive: true,
        isEnabled: this.get('activeBreadcrumbs'),
        nextStep: 1,
        previousStep: null,
        componentName: 'menu/menu-wizard/menu-information',
        display: true
      });
      var step2 = Object.create({
        label: 'menu.builder.steps.taxes.title',
        isActive: this.get('activeBreadcrumbs'),
        isEnabled: this.get('activeBreadcrumbs'),
        nextStep: 2,
        previousStep: 0,
        componentName: 'menu/menu-wizard/taxes',
        display: true
      });
      var step3 = Object.create({
        label: 'menu.builder.steps.paymentInfo.title',
        isActive: this.get('activeBreadcrumbs'),
        isEnabled: this.get('activeBreadcrumbs'),
        nextStep: 3,
        previousStep: 1,
        componentName: 'menu/menu-wizard/payment-information',
        display: true
      });
      var step4 = Object.create({
        label: 'menu.builder.steps.features.title',
        isActive: this.get('activeBreadcrumbs'),
        isEnabled: this.get('activeBreadcrumbs'),
        nextStep: 4,
        previousStep: 2,
        componentName: 'menu/menu-wizard/features',
        display: true
      });
      var step5 = Object.create({
        label: 'menu.builder.steps.receiptOpts.title',
        isActive: this.get('activeBreadcrumbs'),
        isEnabled: this.get('activeBreadcrumbs'),
        nextStep: 5,
        previousStep: 3,
        componentName: 'menu/menu-wizard/receipt-options',
        display: true
      });
      var step6 = Object.create({
        label: 'menu.builder.steps.menuStructure.title',
        isActive: this.get('activeBreadcrumbs'),
        isEnabled: this.get('activeBreadcrumbs'),
        nextStep: null,
        previousStep: 4,
        componentName: 'menu/menu-wizard/menu-structure',
        display: true
      });
      this.set('currentStep', step1);
      return [step1, step2, step3, step4, step5, step6];
    }),
    actions: {
      goToCrumb: function goToCrumb(nextCrumbStep) {
        this.send('cleanMessages');
        this.set('currentStep.isActive', true);
        this.set('currentStep.isEnabled', true);
        this.set('currentStep', nextCrumbStep);
        this.set('wizardComponentName', nextCrumbStep.componentName);
        this.set('currentStep.isActive', true);
        this.set('currentStep.isEnabled', false);

        var currentStepNextStep = this.get('currentStep.nextStep');
        var previousStepIndex = this.get('currentStep.previousStep');
        if (!currentStepNextStep) {
          this.set('isWizardComplete', true);
          this.set('nextStepButtonState', false);
        } else if (this.get('menu.isNew')) {
          this.set('isWizardComplete', false);
          this.set('nextStepButtonState', true);
        } else {
          this.set('isWizardComplete', true);
          this.set('nextStepButtonState', true);
        }

        if (previousStepIndex !== null) {
          this.set('previousStepButtonState', true);
        } else {
          this.set('previousStepButtonState', false);
        }
      },
      nextStep: function nextStep() {
        if (this.get('currentStep.nextStep') !== null) {
          this.send('cleanMessages');
          var nextStepIndex = this.get('currentStep.nextStep');
          if (nextStepIndex) {
            var nextStep = this.get('crumbsList')[nextStepIndex];
            this.set('currentStep.isEnabled', true);
            this.set('currentStep', nextStep);
            if (!nextStep.display) {
              this.send('nextStep');
              return;
            }
            this.set('wizardComponentName', nextStep.componentName);
            this.set('currentStep.isActive', true);
            this.set('currentStep.isEnabled', false);
            this.set('previousStepButtonState', true);
            var currentStepNextStep = this.get('currentStep.nextStep');
            if (!currentStepNextStep) {
              this.set('isWizardComplete', true);
              this.set('nextStepButtonState', false);
            }
          } else {
            this.set('nextStepButtonState', false);
          }
        }
      },
      previousStep: function previousStep() {
        var previousStepIndex = this.get('currentStep.previousStep');
        if (previousStepIndex !== null) {
          this.send('cleanMessages');
          if (this.get('menu.isNew')) {
            this.set('isWizardComplete', false);
          }
          var previousStep = this.get('crumbsList')[previousStepIndex];
          this.set('currentStep.isActive', true);
          this.set('currentStep.isEnabled', true);
          this.set('wizardComponentName', previousStep.componentName);
          if (!this.get('currentStep.previousStep')) {
            this.set('previousStepButtonState', false);
          }
          this.set('currentStep', previousStep);
          if (!previousStep.display) {
            this.send('previousStep');
            return;
          }
          this.set('nextStepButtonState', true);
          this.set('currentStep.isActive', true);
          this.set('currentStep.isEnabled', false);
        }
      },
      showMenuList: function showMenuList() {
        this.set('showModal', true);
      },
      closeModal: function closeModal() {
        this.set('showModal', false);
      },
      returnToMenuList: function returnToMenuList() {
        this.send('cleanMessages');
        this.sendAction('cancel', this.get('menu'));
      },
      save: function save() {
        var _this = this;

        var productTypeTaxNotSelectedCount = '0';
        var productGroupTypeTaxNotSelectedCount = '0';
        var productTypeTaxNotSelected = A();
        var productGroupTypeTaxNotSelected = A();

        var productTypeTaxNotSetCount = '0';
        var productGroupTypeTaxNotSetCount = '0';
        var productTypeTaxNotSet = A();
        var productGroupTypeTaxNotSet = A();
        var isValid = true;
        var errorMessage = undefined;
        if (this.get('menu.categories') && this.get('menu.categories.length') > 0) {
          this.get('menu.categories').forEach(function (category) {
            if (category.get('validations.isValid')) {
              if (category.get('pages') && category.get('pages.length') > 0) {
                category.get('pages').forEach(function (page) {
                  var totalItems = page.get('products.length') + page.get('productGroups.length');
                  if (totalItems > 0) {
                    (function () {
                      if (category.get('pages.length') > 1) {
                        if (isEmpty(page.get('name'))) {
                          isValid = false;
                          errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.nameEmptyMultiplePages'), { closeAfter: 5000 });
                          _this.get('errorMessages').addObject(errorMessage);
                        }
                      }
                      // Create positions array to check for duplicates
                      var positions = A();

                      // Set page name if it's blank
                      var pageName = undefined;
                      if (isEmpty(page.get('name')) || page.get('name') === undefined) {
                        pageName = "Unnamed Page";
                      } else {
                        pageName = page.get('name');
                      }

                      // Validate products
                      page.get('products').forEach(function (product) {

                        var productTypeId = product.get('productCatalog.productType.id');

                        var productTypeTax = _this.get('menu.menuProductTypes').findBy('productType', productTypeId);

                        if (product.get('taxRateType.id') === '1' && productTypeTax.get('taxNotSet')) {
                          var _pageName = page.get('name') ? page.get('name') : "Page1";

                          var message = Object.create({
                            message: category.get('name') + " -> " + _pageName + " -> ",
                            productName: product.get('productCatalog.name')
                          });

                          productTypeTaxNotSet.addObject(message);
                          productTypeTaxNotSetCount++;
                        } else {
                          if (!productTypeTax.get('taxNotSet') && product.get('taxRateType.id') !== '1') {
                            var _pageName2 = page.get('name') ? page.get('name') : "Page1";

                            var message = Object.create({
                              message: category.get('name') + " -> " + _pageName2 + " -> ",
                              productName: product.get('productCatalog.name')
                            });

                            productTypeTaxNotSelected.addObject(message);
                            productTypeTaxNotSelectedCount++;
                          }
                        }

                        if (product.get('validations.isValid')) {
                          product.set('changed', product.get('hasDirtyAttributes'));
                          if (product.get('taxRateType.id') === undefined) {
                            isValid = false;
                            errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.taxRateTypeError', {
                              product: product.get('productCatalog.name'),
                              category: category.get('name'),
                              page: page.get('name')
                            }), { closeAfter: 5000 });
                            _this.get('errorMessages').addObject(errorMessage);
                          }

                          // Validate that the product position is not duplicated
                          if (positions.includes(product.get('position'))) {
                            isValid = false;
                            errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.duplicatedPosition', { page: pageName, position: product.get('position') }), { closeAfter: 5000 });
                            _this.get('errorMessages').addObject(errorMessage);
                          } else {
                            positions.addObject(product.get('position'));
                          }

                          if (!product.get('position')) {
                            isValid = false;
                            errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.positionEmpty', { product: product.get('productCatalog.name'), page: pageName }), { closeAfter: 5000 });
                            _this.get('errorMessages').addObject(errorMessage);
                          }
                        } else {
                          isValid = false;
                          _this.send('displayErrorMessages', product.get('validations.errors'));
                        }
                      });

                      // Validate product groups
                      page.get('productGroups').forEach(function (productGroup) {
                        if (productGroup.get('validations.isValid')) {

                          // Validate that the product group position is not duplicated
                          if (positions.includes(productGroup.get('position'))) {
                            isValid = false;
                            errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.duplicatedPosition', { page: pageName, position: productGroup.get('position') }), { closeAfter: 5000 });
                            _this.get('errorMessages').addObject(errorMessage);
                          } else {
                            positions.addObject(productGroup.get('position'));
                          }

                          productGroup.get('products').forEach(function (product) {

                            var productTypeId = product.get('productCatalog.productType.id');

                            var productTypeTax = _this.get('menu.menuProductTypes').findBy('productType', productTypeId);

                            if (product.get('taxRateType.id') === '1' && productTypeTax.get('taxNotSet')) {
                              var _pageName3 = page.get('name') ? page.get('name') : "Page1";

                              var message = Object.create({
                                message: category.get('name') + " -> " + _pageName3 + " -> " + productGroup.get('name') + " -> ",
                                productName: product.get('productCatalog.name')
                              });

                              productGroupTypeTaxNotSet.addObject(message);
                              productGroupTypeTaxNotSetCount++;
                            } else {
                              if (!productTypeTax.get('taxNotSet') && product.get('taxRateType.id') !== '1') {
                                var _pageName4 = page.get('name') ? page.get('name') : "Page1";

                                var message = Object.create({
                                  message: category.get('name') + " -> " + _pageName4 + " -> " + productGroup.get('name') + " -> ",
                                  productName: product.get('productCatalog.name')
                                });

                                productGroupTypeTaxNotSelected.addObject(message);
                                productGroupTypeTaxNotSelectedCount++;
                              }
                            }
                          });
                        } else {
                          isValid = false;
                          _this.send('displayErrorMessages', productGroup.get('validations.errors'));
                        }
                      });
                    })();
                  }
                });
              }
            } else {
              isValid = false;
              _this.send('displayErrorMessages', category.get('validations.errors'));
            }
          });
        }

        if (isValid) {
          if (productTypeTaxNotSetCount > 0 || productGroupTypeTaxNotSetCount > 0) {
            this.set('productTypeTaxNotSetCount', productTypeTaxNotSetCount);
            this.set('productGroupTypeTaxNotSetCount', productGroupTypeTaxNotSetCount);
            this.get('productTypeTaxNotSet').addObjects(productTypeTaxNotSet);
            this.get('productGroupTypeTaxNotSet').addObjects(productGroupTypeTaxNotSet);
            this.set('showProductTypeTaxNotSetModal', true);
          } else {
            if (productTypeTaxNotSelectedCount > 0 || productGroupTypeTaxNotSelectedCount > 0) {
              this.set('productTypeTaxNotSelectedCount', productTypeTaxNotSelectedCount);
              this.set('productGroupTypeTaxNotSelectedCount', productGroupTypeTaxNotSelectedCount);
              this.get('productTypeTaxNotSelected').addObjects(productTypeTaxNotSelected);
              this.get('productGroupTypeTaxNotSelected').addObjects(productGroupTypeTaxNotSelected);
              this.set('showProductTypeTaxNotSelectedModal', true);
            } else {
              this.set('menu.changed', this.get('menu.hasDirtyAttributes'));
              this.sendAction('save', this.get('menu'), false, true);
            }
          }
        } else {
          this.set('saveEnabled', true);
        }
      },
      saveFromTypeTaxModal: function saveFromTypeTaxModal() {
        this.sendAction('save', this.get('menu'), false, true);
      },
      closeModalTypeTaxModal: function closeModalTypeTaxModal() {
        this.get('productTypeTaxNotSelected').clear();
        this.get('productGroupTypeTaxNotSelected').clear();
        this.set('saveEnabled', true);
        this.set('showProductTypeTaxNotSelectedModal', false);
      },
      closeTypeTaxNotSetModal: function closeTypeTaxNotSetModal() {
        this.get('productTypeTaxNotSet').clear();
        this.get('productGroupTypeTaxNotSet').clear();
        this.set('saveEnabled', true);
        this.set('showProductTypeTaxNotSetModal', false);
      },
      navigateToMenuStructure: function navigateToMenuStructure() {
        this.get('productTypeTaxNotSelected').clear();
        this.get('productTypeTaxNotSet').clear();
        this.get('productGroupTypeTaxNotSelected').clear();
        this.get('productGroupTypeTaxNotSet').clear();
        this.send('goToCrumb', this.get('crumbsList').findBy('nextStep', null));
        this.set('showProductTypeTaxNotSetModal', false);
        this.set('showProductTypeTaxNotSelectedModal', false);
        this.set('saveEnabled', true);
      },
      navigateToTaxesStep: function navigateToTaxesStep() {
        this.set('showProductTypeSelector', true);
        this.get('productTypeTaxNotSet').clear();
        this.get('productGroupTypeTaxNotSet').clear();
        this.send('goToCrumb', this.get('crumbsList').findBy('componentName', 'menu/menu-wizard/taxes'));
        this.set('showProductTypeTaxNotSetModal', false);
        this.set('saveEnabled', true);
      }
    }
  });
});