define('fcs-dashboard/pods/components/gateway-mng/credential/component', ['exports', 'ember', 'fcs-dashboard/mixins/base-component'], function (exports, _ember, _fcsDashboardMixinsBaseComponent) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend(_fcsDashboardMixinsBaseComponent['default'], {
    tagName: '',
    gateways: computed(function () {
      var _this = this;

      return this.get('store').query('gateway', { cashless: false, pageSize: -1 }).then(function (gateways) {
        var filterGateways = [];
        //processor is eventBrite get braintree gateways
        if (_this.get('processor.name').indexOf('EventBrite') > -1) {
          filterGateways.addObjects(gateways.filterBy('processor.name', 'BrainTree'));
        } else if (_this.get('processor.name').indexOf('FCS') > -1) {
          //processor is FCS get Auth.net and PPS gateways
          filterGateways.addObjects(gateways.filterBy('processor.name', 'Auth.net'));
          filterGateways.addObjects(gateways.filterBy('processor.name', 'PPS'));
        } else if (_this.get('processor.name').indexOf('FGT') > -1) {
          //processor is FGT get Auth.net and PPS gateways
          filterGateways.addObjects(gateways.filterBy('processor.name', 'Auth.net'));
          filterGateways.addObjects(gateways.filterBy('processor.name', 'BrainTree'));
          filterGateways.addObjects(gateways.filterBy('processor.name', 'PPS'));
        }
        filterGateways = filterGateways.addObjects(filterGateways.filterBy('processorType.id', 0));
        return filterGateways;
      });
    }),
    showInput: computed(function () {
      return this.get('credential.type') === 1;
    }),
    showDropDown: computed(function () {
      return this.get('credential.type') === 0;
    }),
    selectedOption: computed(function () {
      if (this.get('credential.value') !== '') {
        return this.get('store').peekRecord('gateway', this.get('credential.value'));
      }
    }),
    actions: {
      selectGateway: function selectGateway(item) {
        this.set('selectedOption', item);
        this.set('credential.value', item.get('id'));
      }
    }
  });
});