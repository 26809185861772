define("fcs-dashboard/pods/cashless-information/model", ["exports", "ember-data/attr", "ember-cp-validations", "model-fragments/fragment", "model-fragments/attributes"], function (exports, _emberDataAttr, _emberCpValidations, _modelFragmentsFragment, _modelFragmentsAttributes) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    v4EventId: [(0, _emberCpValidations.validator)('presence', { presence: true, descriptionKey: 'locationGroups.details.cashless.v4EventId.label' }), (0, _emberCpValidations.validator)('number', { gt: '0', descriptionKey: 'locationGroups.details.cashless.v4EventId.label' })]
  });

  exports["default"] = _modelFragmentsFragment["default"].extend(Validations, {
    v4EventId: (0, _emberDataAttr["default"])('number'),
    gateway: (0, _emberDataAttr["default"])('number'),
    reverseUIDEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    enableQRCode: (0, _emberDataAttr["default"])('boolean', { defaultValue: false }),
    promoEmailsEnabled: (0, _emberDataAttr["default"])('boolean', { defaultValue: true }),
    eventUrl: (0, _emberDataAttr["default"])('string'),
    logo: (0, _emberDataAttr["default"])('string'),
    backgroundImage: (0, _emberDataAttr["default"])('string'),
    humanReadableCodeLength: (0, _emberDataAttr["default"])('number'),
    wristbandAttachments: (0, _modelFragmentsAttributes.fragmentArray)('cashlessInformation/attachment', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});