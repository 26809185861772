define("fcs-dashboard/pods/components/menu/menu-wizard/products-table/product-group-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  var run = _ember["default"].run;
  var Logger = _ember["default"].Logger;
  var allSettled = _ember["default"].RSVP.allSettled;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var isEmpty = _ember["default"].isEmpty;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], {
    productClassesReduced: ['REGULAR', 'TEMPLATE'],
    productGroupName: computed(function () {
      if (this.get('productGroup.name') !== null && this.get('productGroup.name') !== undefined && !isEmpty(this.get('productGroup.name'))) {
        return this.get('productGroup.name');
      } else {
        return '';
      }
    }),
    _isLoading: true,
    displayProductCatalogsList: false,
    displayProductsList: false,
    refreshList: true,
    productCatalogsLoaded: false,
    metaProductCatalogsLoaded: false,
    data: [],
    columns: computed(function () {
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product')
      }, {
        propertyName: 'productType.name',
        title: this.get('intl').t('productCatalog.list.productType')
      }, {
        propertyName: 'suggestedPrice',
        title: this.get('intl').t('productCatalog.list.suggestedPrice'),
        template: "product-catalog/suggested-price-cell",
        className: 'suggestedPriceRow'
      }, {
        propertyName: 'displayClassName',
        title: this.get('intl').t('productCatalog.list.productCatalogClass'),
        filterBy: 'displayClassName',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('productClassesReduced')
      }];
    }),
    attributeSetsColumns: computed(function () {
      return [{
        propertyName: 'attributeSetName',
        title: this.get('intl').t('metaProductCatalog.modal.attributeSet.name')
      }, {
        propertyName: 'priority',
        title: this.get('intl').t('metaProductCatalog.modal.attributeSet.priority'),
        template: "meta-product-catalog/priority-cell"
      }];
    }),
    loadReady: computed('productCatalogsLoaded', 'metaProductCatalogsLoaded', function () {
      return this.get('productCatalogsLoaded') && this.get('metaProductCatalogsLoaded');
    }),
    selectedModels: A(),
    showModalProducts: false,
    newProduct: computed(function () {
      return this.get('store').createRecord('productCatalog', {
        company: this._getCompany(),
        currency: 'USD',
        suggestedPrice: 0,
        custom: false
      });
    }),
    positions: computed(function () {
      return this.createPositions();
    }),
    sortCriteria: ['position'],
    sortedProducts: computed.sort('productGroup.products', 'sortCriteria'),
    createPositions: function createPositions() {
      var positions = A();
      Logger.debug("# of product positions 24");
      for (var i = 1; i < 25; i++) {
        positions.addObject(i);
      }
      return positions;
    },
    _getCompany: function _getCompany() {
      var company = undefined;
      if (this.get('isBRUser')) {
        company = this.get('menu.company');
      } else {
        company = this.get('loggedAccount.company');
      }
      return company;
    },
    _taxRateTypes: computed(function () {
      return this.get('store').findAll('taxRateType');
    }),
    _loadTaxRateTypes: function _loadTaxRateTypes() {
      var _this = this;

      if (this.get('_taxRateTypes').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('_taxRateTypes').then(function (content) {
          run(function () {
            _this.set('_taxRateTypes', content);
          });
        });
      }
    },
    _menuProductTypes: [],
    _loadProductTypes: function _loadProductTypes() {
      var _this2 = this;

      var promise = [];
      promise = this.get('menu.menuProductTypes').mapBy('productTypeObject');
      allSettled(promise).then(function () {
        _this2.set('_menuProductTypes', promise);
        _this2.set('_isLoading', false);
      });
    },
    _updateAttributeSets: function _updateAttributeSets(item) {
      var _this3 = this;

      if (item.get('metaProductCatalogId') !== null && item.get('metaProductCatalogId') !== undefined) {
        item.get('attributes').forEach(function (attribute) {
          var templateAttributeSet = _this3.get('store').findRecord('attributeSet', attribute.get('attributeSetId'));
          if (templateAttributeSet.then !== undefined) {
            templateAttributeSet.then(function (content) {
              run(function () {
                templateAttributeSet = content;
                if (_this3.get('productGroup.attributeSets.length') > 0) {
                  var containsAttributeSet = false;
                  _this3.get('productGroup.attributeSets').forEach(function (attributeSet) {
                    if (templateAttributeSet.get('id') === attributeSet.get('attributeSetId')) {
                      containsAttributeSet = true;
                    }
                  });

                  if (!containsAttributeSet) {
                    _this3.get('productGroup.attributeSets').addObject(_this3.get('store').createFragment('productGroupAttributeSet', {
                      attributeSetId: templateAttributeSet.get('id'),
                      attributeSetName: templateAttributeSet.get('name'),
                      priority: templateAttributeSet.get('priority')
                    }));
                  }
                } else {
                  _this3.get('productGroup.attributeSets').addObject(_this3.get('store').createFragment('productGroupAttributeSet', {
                    attributeSetId: templateAttributeSet.get('id'),
                    attributeSetName: templateAttributeSet.get('name'),
                    priority: templateAttributeSet.get('priority')
                  }));
                }
              });
            });
          } else {
            if (_this3.get('productGroup.attributeSets.length') > 0) {
              var containsAttributeSet = false;
              _this3.get('productGroup.attributeSets').forEach(function (attributeSet) {
                if (templateAttributeSet.get('id') === attributeSet.get('attributeSetId')) {
                  containsAttributeSet = true;
                }
              });

              if (!containsAttributeSet) {
                _this3.get('productGroup.attributeSets').addObject(_this3.get('store').createFragment('productGroupAttributeSet', {
                  attributeSetId: templateAttributeSet.get('id'),
                  attributeSetName: templateAttributeSet.get('name'),
                  priority: templateAttributeSet.get('priority')
                }));
              }
            } else {
              _this3.get('productGroup.attributeSets').addObject(_this3.get('store').createFragment('productGroupAttributeSet', {
                attributeSetId: templateAttributeSet.get('id'),
                attributeSetName: templateAttributeSet.get('name'),
                priority: templateAttributeSet.get('priority')
              }));
            }
          }
        });
      }
    },
    _initializeSelectedModels: function _initializeSelectedModels() {
      var _this4 = this;

      // Initialize Selected Models if Null or Undefined
      if (this.get('selectedModels') === null || this.get('selectedModels') === undefined) {
        this.set('selectedModels', A());
      }

      if (this.get('selectedModels.length') === 0) {
        (function () {
          var catalog = A();
          _this4.get('productGroup.products').forEach(function (product) {
            var productCatalog = _this4.get('data').filterBy('id', product.get('productCatalog.id')).get('firstObject');
            catalog.addObject(productCatalog);
          });
          _this4.get('selectedModels').clear();
          _this4.get('selectedModels').addObjects(catalog);
        })();
      }
    },
    imagePreview: computed('productGroup.image', 'productGroup.imageURL', function () {
      var url = "";
      if (this.get('productGroup.image')) {
        url = this.get('productGroup.image');
      } else {
        url = this.get('productGroup.imageURL');
      }
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    init: function init() {
      var _this5 = this;

      this._super.apply(this, arguments);
      this.set('selectedModels', null);
      this.get('data').clear();

      var company = this.get('menu.company.id');
      Logger.debug("COMPANY USER: Getting products from catalog for company " + company + ".");
      this.get('store').query('productCatalog', { companyId: company, loadAttributes: true, archived: false }).then(function (productCatalogs) {
        _this5.get('data').addObjects(productCatalogs.filterBy('productClass.id', '0'));
        _this5.set('productCatalogsLoaded', true);
      });
      this.get('store').query('metaProductCatalog', { companyId: company, archived: false }).then(function (templates) {
        _this5.get('data').addObjects(templates);
        _this5.set('metaProductCatalogsLoaded', true);
      });
      this._loadTaxRateTypes();
      this._loadProductTypes();
    },
    actions: {
      clearAllSelected: function clearAllSelected() {
        var _this6 = this;

        this.set('refreshList', false);
        run.next(function () {
          _this6.get('selectedModels').clear();
          _this6.set('refreshList', true);
        });
      },
      addProducts: function addProducts() {
        var _this7 = this;

        this.send('cleanMessages');

        this._updateSelectedProductsByMetaProducts();

        this.get('selectedModels').forEach(function (item) {
          // Adds new attribute sets
          _this7._updateAttributeSets(item);

          var contains = false;

          _this7.get('productGroup.products').forEach(function (product) {
            if (product.get('productCatalog.id') === item.get('id')) {
              contains = true;
            }
          });

          if (!contains) {
            // Sets the position of the new product
            // the new position will be the first free position of 24
            var newPosition = 1;
            for (var position = 0; position < _this7.get('sortedProducts.length'); position++) {
              if (_this7.get('sortedProducts').filterBy('position', newPosition).length === 0) {
                newPosition = position + 1;
                break;
              } else {
                newPosition++;
              }
            }
            var productTypeTax = _this7.get('menu.menuProductTypes').findBy('productTypeObject.id', item.get('productType.id'));

            var taxRate = 0;
            var taxRateType = '0';
            if (!productTypeTax.get('taxNotSet')) {
              taxRate = parseInt(productTypeTax.get('taxRate'));
              taxRateType = '1';
            }

            _this7.get('productGroup.products').addObject(_this7.get('store').createRecord('product', {
              productCatalog: item,
              price: item.get('suggestedPrice'),
              taxRate: taxRate,
              productTypeTaxRate: 0,
              toppings: [],
              allowModifications: false,
              taxRateType: _this7.get('_taxRateTypes').findBy('id', taxRateType),
              position: newPosition,
              productType: item.get('productType.name')
            }));
            _this7.get('positions').addObject(newPosition);
          }
        });
        this.set('displayProductCatalogsList', false);
      },
      deleteProduct: function deleteProduct(item) {
        var _this8 = this;

        this._initializeSelectedModels();
        this.set('displayProductsList', false);
        this.get('productGroup.products').removeObject(item);
        if (item.get('id') !== null && item.get('id') !== undefined && item.get('id') !== 0) {
          this.get('productGroup.deletedProducts').addObject(item.get('id'));
        }
        var productToRemove = this.get('selectedModels').filterBy('id', item.get('productCatalog.id'));
        this.get('selectedModels').removeObjects(productToRemove);

        var attributesToRemove = A();
        this.get('productGroup.attributeSets').forEach(function (attributeSet) {
          var inUse = false;
          _this8.get('selectedModels').forEach(function (item) {
            if (item.get('metaProductCatalogId') !== null && item.get('metaProductCatalogId') !== undefined) {
              item.get('attributes').forEach(function (attribute) {
                var templateAttributeSet = _this8.get('store').peekRecord('attributeSet', attribute.get('attributeSetId'));
                if (templateAttributeSet.get('id') === attributeSet.get('attributeSetId')) {
                  inUse = true;
                }
              });
            }
          });

          if (!inUse) {
            attributesToRemove.addObject(attributeSet);
          }
        });

        if (attributesToRemove.length > 0) {
          this.get('productGroup.attributeSets').removeObjects(attributesToRemove);
        }
        this.set('displayProductsList', true);
        this.set('positions', this.createPositions());
      },
      closeModalProductGroups: function closeModalProductGroups() {
        // Initialize Selected Models if Null or Undefined
        if (this.get('selectedModels') === null || this.get('selectedModels') === undefined) {
          this.set('selectedModels', A());
        }
        this.get('selectedModels').clear();
        this.sendAction('closeModalProductGroups');
      },
      displayProductCatalogs: function displayProductCatalogs() {
        this._initializeSelectedModels();
        this.set('displayProductCatalogsList', true);
      },
      addProductGroup: function addProductGroup() {
        var _this9 = this;

        this.set('productGroup.name', this.get('productGroupName'));
        if (this.get('productGroup.validations.isValid')) {
          if (this.get('selectedModels') !== null && this.get('selectedModels') !== undefined) {
            this.get('selectedModels').clear();
          }
          this.sendAction('addProductGroup', this.get('productGroup'));
        } else {
          this.send('cleanMessages');
          this.get('productGroup.validations.errors').forEach(function (error) {
            var errorMessage = _this9.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this9.get('errorMessages').addObject(errorMessage);
          });
        }
      },
      removeImage: function removeImage() {
        this.set('imageName', null);
        this.set('productGroup.image', null);
        this.set('productGroup.imageURL', null);
        this.set('productGroup.deleteImage', true);
      },
      uploadImage: function uploadImage(fileList) {
        var _this10 = this;

        var re = new RegExp('image/(png|jpeg)');
        if (fileList[0].size <= 10000000) {
          if (re.test(fileList[0].type)) {
            (function () {
              var that = _this10;
              _this10.set('imageName', fileList[0].name);
              var reader = new FileReader();

              reader.onloadend = function () {
                that.set('productGroup.deleteImage', false);
                that.set('productGroup.image', reader.result);
              };

              reader.readAsDataURL(fileList[0]);
            })();
          } else {
            Logger.debug('not an image.');
            var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageFormat'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        } else {
          Logger.debug('image size max is 10mb.');
          var errorMessage = this.get('notify').alert(this.get('intl').t('productCatalog.modal.errors.invalidImageSize'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
      }
    }
  });
});