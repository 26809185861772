define("fcs-dashboard/pods/components/location-group/promotion-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Component = _ember["default"].Component;
  var isEmpty = _ember["default"].isEmpty;
  var run = _ember["default"].run;
  var computed = _ember["default"].computed;
  var A = _ember["default"].A;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    gettingBinList: false,
    showBins: false,
    binList: '',
    _companies: [],
    products: [],
    _imagePlacements: [],
    creditCardTypeEnabled: true,
    csvFile: null,
    sortedPromotionTriggers: [],
    selectedImagePlacements: [],
    disabled: false,
    displayItemTokenPromotionProducts: false,
    displaySelectedItemTokenPromotionProducts: false,
    filteredPromotionRedemptionRules: [],
    columns: computed(function () {
      return [{
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product')
      }, {
        propertyName: 'companyName',
        title: this.get('intl').t('productCatalog.list.company')
      }, {
        propertyName: 'productType.name',
        title: this.get('intl').t('productCatalog.list.productType')
      }];
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('promotion.binCount') !== null && this.get('promotion.binCount') !== undefined && this.get('promotion.binCount') > 0) {
        this.set('creditCardTypeEnabled', false);
      }

      if (this.get('promotion.images') !== null && this.get('promotion.images') !== undefined && this.get('promotion.images.length') > 0) {
        (function () {
          var promotionImages = A();
          _this.get('promotion.images').forEach(function (image) {
            if (!promotionImages.includes(image) && !image.get('deleted') && (image.get('imagePlacement.id') == 1 || image.get('imagePlacement.id') == 3)) {
              promotionImages.addObject(image);
            }
          });

          _this.get('promotion.images').clear();
          _this.get('promotion.images').addObjects(promotionImages);
        })();
      }

      if (this.get('promotion.promotionRedemptionRule.id') === 1 || this.get('promotion.promotionRedemptionRule.id') === '1') {
        (function () {
          var endpoint = "/api/v1/productCatalogs/companies";
          var _authToken = _this.get('session.data.authenticated.access_token');
          var options = {
            url: endpoint,
            type: 'GET',
            data: { companies: _this.get('_companies') }
          };
          options.beforeSend = function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
          };
          options.success = function (products) {
            _this.set('products', products.productCatalogs);
            _this.addSelectedProductCatalogsToTable();
          };
          options.error = function () {
            var errorMessage = undefined;
            // Catch error and return proper error message
            errorMessage = _this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          };
          _ember["default"].$.ajax(options);
        })();
      }
      if (this.get('promotion.status') !== null && this.get('promotion.status') !== undefined && this.get('promotion.status.id') !== '0') {
        this.set('disabled', true);
      }
      //initialize promotionTypes
      if (this.get('promotionTypes').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('promotionTypes').then(function (content) {
          run(function () {
            _this.set('promotionTypes', content);
          });
        });
      }
      //initialize promotionTriggerTypes
      if (this.get('promotionTriggerTypes').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('promotionTriggerTypes').then(function (content) {
          run(function () {
            _this.set('promotionTriggerTypes', content);
          });
        });
      }
      //initialize creditCards
      if (this.get('creditCards').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('creditCards').then(function (content) {
          run(function () {
            var removeCreditCardType = content.findBy('id', '4');
            content.removeObject(removeCreditCardType);
            _this.set('creditCards', content);
          });
        });
      } else {
        var removeCreditCardType = this.get('creditCards').findBy('id', '4');
        this.get('creditCards').removeObject(removeCreditCardType);
      }

      this.get('filteredPromotionRedemptionRules').clear();
      var filteredPromotionRedemptionRules = [];
      //initialize promotionRedemptionRules
      if (this.get('promotionRedemptionRules').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('promotionRedemptionRules').then(function (content) {
          run(function () {
            _this.set('promotionRedemptionRules', content);
            filteredPromotionRedemptionRules.addObjects(content.filterBy('id', '1'));
            filteredPromotionRedemptionRules.addObjects(content.filterBy('id', '2'));
            _this.get('filteredPromotionRedemptionRules').addObjects(filteredPromotionRedemptionRules);
          });
        });
      } else {
        filteredPromotionRedemptionRules.addObjects(this.get('promotionRedemptionRules').filterBy('id', '1'));
        filteredPromotionRedemptionRules.addObjects(this.get('promotionRedemptionRules').filterBy('id', '2'));
        this.get('filteredPromotionRedemptionRules').addObjects(filteredPromotionRedemptionRules);
      }

      this.getPromotionImagePlacements();
      if (this.get('promotion.type') !== null && this.get('promotion.type') !== undefined) {
        this.setPromotionTriggers(this.get('promotion.type'));
      }
    },

    getPromotionImagePlacements: function getPromotionImagePlacements() {
      var _this2 = this;

      var promotionImagePlacements = this.get('store').findAll('promotionImagePlacement');
      this.get('_imagePlacements').clear();
      var filteredPromotionImagePlacement = [];
      if (promotionImagePlacements.then !== undefined) {
        promotionImagePlacements.then(function (content) {
          run.later(function () {
            filteredPromotionImagePlacement.addObjects(content.filterBy('id', '1'));
            filteredPromotionImagePlacement.addObjects(content.filterBy('id', '3'));
            _this2.get('_imagePlacements').addObjects(filteredPromotionImagePlacement);
          }, 1000);
        });
      } else {
        filteredPromotionImagePlacement.addObjects(promotionImagePlacements.filterBy('id', '1'));
        filteredPromotionImagePlacement.addObjects(promotionImagePlacements.filterBy('id', '3'));
        this.get('_imagePlacements').addObjects(filteredPromotionImagePlacement);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('promotion', null);
    },
    addSelectedProductCatalogsToTable: function addSelectedProductCatalogsToTable() {
      var _this3 = this;

      this.set('displaySelectedItemTokenPromotionProducts', false);
      var catalog = A();
      this.get('promotion.productCatalogs').forEach(function (product) {
        var productCatalog = _this3.get('products').filterBy('id', product);
        catalog.addObjects(productCatalog);
      });
      this.set('selectedItemTokenPromotionProducts', catalog);
      this.set('displaySelectedItemTokenPromotionProducts', true);
    },
    setPromotionTriggers: function setPromotionTriggers(promotionType) {
      this.get('sortedPromotionTriggers').clear();
      if (promotionType.get('id') === 0 || promotionType.get('id') === '0') {
        this.get('sortedPromotionTriggers').addObjects(this.get('promotionTriggerTypes'));
      } else {
        this.set('promotion.minimumSpentTotal', 0);
        this.get('sortedPromotionTriggers').addObjects(this.get('promotionTriggerTypes').filter(function (trigger) {
          if (trigger.id !== '2') {
            return trigger;
          }
        }));
      }
    },
    actions: {
      addPromotion: function addPromotion(promotion) {
        var _this4 = this;

        var isValid = true;
        if (isEmpty(promotion.get('name'))) {
          isValid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.emptyName'), { closeAfter: 5000 });
        }

        if (this.get('promotion.images') !== null && this.get('promotion.images') !== undefined && this.get('promotion.images.length') > 0) {
          this.get('promotion.images').forEach(function (image) {
            if (!image.get('deleted') && (image.get('imagePlacement.id') === null || image.get('imagePlacement.id') === undefined)) {
              isValid = false;
              _this4.get('notify').alert(_this4.get('intl').t('locationGroups.details.promotions.modal.errors.nullImagePlacement'), { closeAfter: 5000 });
            }

            if (!image.get('deleted') && (image.get('image') === null || image.get('image') === undefined) && (image.get('imageURL') === null || image.get('imageURL') === undefined)) {
              isValid = false;
              _this4.get('notify').alert(_this4.get('intl').t('locationGroups.details.promotions.modal.errors.nullImage'), { closeAfter: 5000 });
            }
          });
        }

        var duplicatedPlacements = [];
        if (this.get('promotion.images.length') > 1) {
          this.get('promotion.images').forEach(function (image) {
            var imagesWithPlacement = _this4.get('promotion.images').filterBy('imagePlacement.id', image.get('imagePlacement.id')).filterBy('deleted', false);
            if (imagesWithPlacement.length > 1) {
              isValid = false;
              duplicatedPlacements.addObject(image.get('imagePlacement'));
            }
          });
        }

        if (!isValid && duplicatedPlacements.length > 0) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.duplicatedImagePlacement'), { closeAfter: 5000 });
        }

        if (this.get('creditCardTypeEnabled')) {
          this.set('promotion.binCount', 0);
          this.set('promotion.binCsv', null);
        }

        if (isValid) {
          this.sendAction('addPromotion', promotion);
        }
      },
      editPromotion: function editPromotion(promotion) {
        var _this5 = this;

        var isValid = true;
        if (isEmpty(promotion.get('name'))) {
          isValid = false;
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.emptyName'), { closeAfter: 5000 });
        }

        if (this.get('promotion.images') !== null && this.get('promotion.images') !== undefined && this.get('promotion.images.length') > 0) {
          this.get('promotion.images').forEach(function (image) {
            if (!image.get('deleted') && (image.get('imagePlacement.id') === null || image.get('imagePlacement.id') === undefined)) {
              isValid = false;
              _this5.get('notify').alert(_this5.get('intl').t('locationGroups.details.promotions.modal.errors.nullImagePlacement'), { closeAfter: 5000 });
            }

            if (!image.get('deleted') && (image.get('image') === null || image.get('image') === undefined) && (image.get('imageURL') === null || image.get('imageURL') === undefined)) {
              isValid = false;
              _this5.get('notify').alert(_this5.get('intl').t('locationGroups.details.promotions.modal.errors.nullImage'), { closeAfter: 5000 });
            }
          });
        }

        var duplicatedPlacements = [];
        if (this.get('promotion.images.length') > 1) {
          this.get('promotion.images').forEach(function (image) {
            var imagesWithPlacement = _this5.get('promotion.images').filterBy('imagePlacement.id', image.get('imagePlacement.id')).filterBy('deleted', false);
            if (imagesWithPlacement.length > 1) {
              isValid = false;
              duplicatedPlacements.addObject(image.get('imagePlacement'));
            }
          });
        }

        if (!isValid && duplicatedPlacements.length > 0) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.duplicatedImagePlacement'), { closeAfter: 5000 });
        }

        if (this.get('creditCardTypeEnabled')) {
          this.set('promotion.binCount', 0);
          this.set('promotion.binCsv', null);
        }

        if (isValid) {
          this.sendAction('editPromotion', promotion);
        }
      },
      cancelPromotionModal: function cancelPromotionModal() {
        this.sendAction('cancelPromotionModal');
      },
      changePromotionType: function changePromotionType(promotionType) {
        if (promotionType.id !== 1 && promotionType.id !== '1') {
          this.get('promotion.productCatalogs').clear();
        }

        this.setPromotionTriggers(promotionType);

        if (this.get('promotion.type.id') === '0' && promotionType.id !== '0' && this.get('promotion.triggerType.id') === '2') {
          this.set('promotion.triggerType', this.get('sortedPromotionTriggers').findBy('id', '0'));
        }

        this.set('promotion.type', promotionType);
      },
      changeRedemptionRule: function changeRedemptionRule(redemptionRule) {
        var _this6 = this;

        if (redemptionRule.id !== 1 && redemptionRule.id !== '1') {
          this.set('displaySelectedItemTokenPromotionProducts', false);
          this.get('promotion.productCatalogs').clear();
          this.set('promotion.promotionRedemptionRule', redemptionRule);
        } else {
          (function () {
            var endpoint = "/api/v1/productCatalogs/companies";
            var _authToken = _this6.get('session.data.authenticated.access_token');
            var options = {
              url: endpoint,
              type: 'GET',
              data: { companies: _this6.get('_companies') }
            };
            options.beforeSend = function (xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
            };
            options.success = function (products) {
              _this6.set('products', products.productCatalogs);
              _this6.addSelectedProductCatalogsToTable();
              _this6.set('displaySelectedItemTokenPromotionProducts', true);
              _this6.set('promotion.promotionRedemptionRule', redemptionRule);
            };
            options.error = function () {
              var errorMessage = undefined;
              // Catch error and return proper error message
              errorMessage = _this6.get('notify').alert(_this6.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 });
              _this6.get('errorMessages').addObject(errorMessage);
            };
            _ember["default"].$.ajax(options);
          })();
        }
      },
      selectProductsModal: function selectProductsModal() {
        this.set('displayItemTokenPromotionProducts', true);
      },
      addProducts: function addProducts(productCatalogs) {
        this.set('promotion.productCatalogs', productCatalogs);
        this.addSelectedProductCatalogsToTable();
        this.set('displayItemTokenPromotionProducts', false);
      },
      closeModalProducts: function closeModalProducts() {
        this.send('cleanMessages');
        this.set('displayItemTokenPromotionProducts', false);
      },
      changeRedemptionExpirationDateTime: function changeRedemptionExpirationDateTime(value) {
        this.set('promotion.redemptionExpirationDateTime', value);
      },
      changeCardType: function changeCardType(cardType) {
        this.set('promotion.cardType', cardType);
      },
      changeTriggerType: function changeTriggerType(triggerType) {
        this.set('promotion.triggerType', triggerType);
      },
      clearRedemptionExpirationDateTime: function clearRedemptionExpirationDateTime() {
        this.set('promotion.redemptionExpirationDateTime', null);
      },
      removeItem: function removeItem(item) {
        if (item.get('isNew')) {
          this.get('promotion.images').removeObject(item);
          item.deleteRecord();
        } else {
          item.set('deleted', true);
        }
      },
      addImage: function addImage() {
        var totalImages = this.get('promotion.images').filterBy('deleted', false);
        if (totalImages.length < 2) {
          var image = this.get('store').createRecord('promotionImage');
          this.get('promotion.images').addObject(image);
        } else {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.imagesLimit'), { closeAfter: 5000 });
        }
      },
      changeImagePlacement: function changeImagePlacement(promotionImage, placement) {
        if (promotionImage.get('id') !== null && promotionImage.get('id') !== undefined) {
          var image = this.get('promotion.images').findBy('id', promotionImage.id);
          image.set('imagePlacement', placement);
        } else {
          promotionImage.set('imagePlacement', placement);
        }
      },
      uploadFile: function uploadFile(fileList) {
        var _this7 = this;

        this.set('binList', '');
        this.set('showBins', false);
        var re = new RegExp('text/csv');
        if (fileList[0].size <= 1000000) {
          if (re.test(fileList[0].type)) {
            (function () {
              var that = _this7;
              //that.set('promotion.binCsv', fileList[0]);

              var reader = new FileReader();

              reader.onloadend = function () {
                that.set('promotion.binCsv', reader.result);
              };

              reader.readAsDataURL(fileList[0]);
            })();
          } else {
            Logger.debug('not a csv.');
            var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.invalidFileType'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        } else {
          Logger.debug('file size max is 1mb.');
          var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.invalidFileSize'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
      },
      removeFile: function removeFile() {
        this.set('promotion.binCsv', null);
      },
      clearBins: function clearBins() {
        this.set('promotion.binCount', 0);
        this.set('promotion.binCsv', null);
      },
      downloadBins: function downloadBins() {
        var _this8 = this;

        this.set('gettingBinList', true);
        var endpoint = "/api/v1/promotions/binCsv/";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'GET',
          data: { promotionId: parseInt(this.get('promotionId')) }
        };

        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function (result, status) {
          _this8.set('gettingBinList', false);
          _this8.set('binList', result);
          _this8.set('showBins', true);
        };
        options.error = function (error) {
          _this8.get('notify').alert(_this8.get('intl').t('locationGroups.details.promotions.modal.errors.errorGettingBinList'), { closeAfter: 5000 });
          _this8.set('gettingBinList', false);
          console.log('Error: ' + error.responseText);
        };
        _ember["default"].$.ajax(options);
      },
      changeCreditCardTypeEnabled: function changeCreditCardTypeEnabled(value) {
        this.set('creditCardTypeEnabled', value.newValue);
        if (value.newValue) {
          this.set('promotion.binCsv', null);
        } else {
          this.set('promotion.cardType', null);
        }
      }
    }
  });
});