define("fcs-dashboard/pods/components/search/search-criteria/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var run = _ember["default"].run;
  var isEmpty = _ember["default"].isEmpty;
  var Logger = _ember["default"].Logger;
  var isEqual = _ember["default"].isEqual;
  exports["default"] = Component.extend(_fcsDashboardMixinsBaseComponent["default"], {
    tagName: '',
    //*******************search options aliases
    searchText: computed.alias('searchOptions.searchText'),
    orderId: computed.alias('searchOptions.orderId'),
    orderNote: computed.alias('searchOptions.orderNote'),
    cashUid: computed.alias('searchOptions.cashUid'),
    accessLevel: computed.alias('searchOptions.accessLevel'),
    reference: computed.alias('searchOptions.reference'),
    authCode: computed.alias('searchOptions.authCode'),
    product: computed.alias('searchOptions.product'),
    serviceFee: computed.alias('searchOptions.serviceFee'),
    tips: computed.alias('searchOptions.tips'),
    fromDate: computed.alias('searchOptions.fromDate'),
    toDate: computed.alias('searchOptions.toDate'),
    lastName: computed.alias('searchOptions.lastName'),
    lastFour: computed.alias('searchOptions.lastFour'),
    ccExpiration: computed.alias('searchOptions.ccExpiration'),
    tabName: computed.alias('searchOptions.tabName'),
    selectedTenders: computed.alias('searchOptions.selectedTenders'),
    selectedCCTypes: computed.alias('searchOptions.selectedCCTypes'),
    users: computed.alias('searchOptions.users'),
    userProfiles: computed.alias('searchOptions.userProfiles'),
    locationGroups: computed.alias('searchOptions.locationGroups'),
    locations: computed.alias('searchOptions.locations'),
    vendors: computed.alias('searchOptions.vendors'),
    menus: computed.alias('searchOptions.menus'),
    productTypes: computed.alias('searchOptions.productTypes'),
    priceStatuses: computed.alias('searchOptions.priceStatuses'),
    tabStatuses: computed.alias('searchOptions.tabStatuses'),
    paymentStatuses: computed.alias('searchOptions.paymentStatuses'),
    orderStatuses: computed.alias('searchOptions.orderStatuses'),
    paymentModes: computed.alias('searchOptions.paymentModes'),
    gateways: computed.alias('searchOptions.gateways'),
    selectedOrderStatus: computed.alias('searchOptions.selectedOrderStatus'),
    selectedGratuities: [],
    allUsers: [],
    filteredUsers: [],
    selectedEntityOrderStatus: null,
    showExportModal: false,

    // Get all options for dropdowns
    tenderTypes: computed(function () {
      return this.get('store').findAll('tenderType');
    }),
    _availableTenders: [],
    availableCreditCardTypes: computed(function () {
      return this.get('store').findAll('creditCardType');
    }),
    _ccTypes: [],
    availableOrderStatuses: computed(function () {
      return this.get('store').findAll('orderStatus');
    }),
    availableUserProfiles: computed(function () {
      return this.get('store').findAll('userProfile');
    }),
    availableGateways: computed(function () {
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          Logger.debug('BR-user (locked to a company): Getting all gateways from locked company.');
          var company = this.get('lockedCompany.id');
          return this.get('store').query('gateway', { companyId: company, pageSize: -1 });
        } else {
          Logger.debug('BR-user: Getting all gateways.');
          return this.get('store').query('gateway', { pageSize: -1 });
        }
      } else {
        Logger.debug('Company user: Getting gateways from company.');
        var company = this.get('loggedAccount.company.id');
        return this.get('store').query('gateway', { companyId: company, pageSize: -1 });
      }
    }),
    availableProductsTypes: computed(function () {
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          Logger.debug('BR-user (locked to a company): Getting all productType from locked company.');
          var company = this.get('lockedCompany.id');
          return this.get('store').query('productType', { companyId: company, pageSize: -1 });
        } else {
          Logger.debug('BR-user: Getting all productType.');
          return this.get('store').query('productType', { pageSize: -1 });
        }
      } else {
        Logger.debug('Company user: Getting productType from company.');
        var company = this.get('loggedAccount.company.id');
        return this.get('store').query('productType', { companyId: company, pageSize: -1 });
      }
    }),

    // Static options for dropdowns
    _priceStatuses: [{ id: 0, name: 'Complementary' }, { id: 1, name: 'Voided' }, { id: 2, name: 'Employee Discount' }, {
      id: 3,
      name: 'Spilled'
    }, { id: 4, name: 'Tab Discounted' }, { id: 5, name: 'Regular Price' }, { id: 6, name: 'Automatic Cancel' }, {
      id: 7,
      name: 'Deleted'
    }, { id: 8, name: 'Buy-One-Get-One' }],
    _gratuities: [{ id: 0, name: 'Tips' }, { id: 1, name: 'Service Fee' }],
    _orderPaymentStatuses: [{ id: 0, name: 'Approved' }, { id: 1, name: 'Declined' }, { id: 2, name: 'Partial' }],
    _paymentStatuses: [{ id: 0, name: 'Created' }, { id: 1, name: 'Error' }, { id: 2, name: 'Approved' }, {
      id: 3,
      name: 'Declined'
    }, { id: 4, name: 'Settled' }, { id: 5, name: 'Held' }, { id: 6, name: 'Voided' }, {
      id: 7,
      name: 'Automatic Cancel'
    }, { id: 8, name: 'Deleted' }],
    _paymentModes: [{ id: 0, name: 'Real Time' }, { id: 1, name: 'Batch' }],
    _tabStatuses: [{ id: 0, name: 'Regular Price' }, { id: 1, name: 'Discounted' }, { id: 2, name: 'Complementary' }, {
      id: 3,
      name: 'Spilled'
    }, { id: 4, name: 'Voided' }, { id: 5, name: 'Walked' }, { id: 6, name: 'Paid' }, { id: 7, name: 'Deleted' }],
    _orderStatus: [],
    endDate: new Date(),
    sortDefinition: ['name'],
    sortedLocationGroups: computed.sort('_locationGroups', 'sortDefinition'),
    // Get locationGroups according to company and lock status
    _locationGroups: computed(function () {
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          Logger.debug('BR-user (locked to a company): Getting all location groups from locked company.');
          var company = this.get('lockedCompany.id');
          return this.get('store').query('locationGroup', { companyId: company, pageSize: -1 });
        } else {
          Logger.debug('BR-user: Getting all location groups.');
          var cachedRecords = this.get('store').peekAll('locationGroup');
          if (!isEmpty(cachedRecords)) {
            this.get('store').query('locationGroup', { pageSize: -1 });
            return cachedRecords;
          }
          return this.get('store').query('locationGroup', { pageSize: -1 });
        }
      } else {
        Logger.debug('Company user: Getting location groups from company.');
        var company = this.get('loggedAccount.company.id');
        return this.get('store').query('locationGroup', { companyId: company, pageSize: -1 });
      }
    }),
    _vendorCompanies: [],

    // Filter VMCs
    _filteredVendorCompanies: computed('_vendorCompanies', 'locationGroups', 'vendors', function () {
      return this.get('_vendorCompanies').sortBy('companyName');
    }),
    // Get all menus according to company and lock status
    _menus: computed(function () {
      var locationGroupId = null;
      if (this._getSingleSelectedLocationGroup(null) !== null && this._getSingleSelectedLocationGroup(null) !== undefined) {
        locationGroupId = this._getSingleSelectedLocationGroup(null).id;
      }
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          Logger.debug('BR-user (locked to a company): Getting all menus from locked company.');
          var companyId = this.get('lockedCompany.id');
          return this.get('store').query('menuNameFilter', { companyId: companyId, locationGroupId: locationGroupId });
        } else {
          Logger.debug('BR-user: Getting all menus.');
          return this.get('store').query('menuNameFilter', {});
        }
      } else {
        Logger.debug('Company user: Getting menus from company.');
        var companyId = this.get('loggedAccount.company.id');

        return this.get('store').query('menuNameFilter', { companyId: companyId, locationGroupId: locationGroupId });
      }
    }),
    // Filter menus list when one of the next attributes changes: 'sortedMenus', 'locations', 'vendors', '_filteredLocations', 'locationGroups'
    _filteredMenus: computed('sortedMenus', 'locations', 'vendors', '_filteredLocations', 'locationGroups', function () {
      var _this = this;

      if (this.get('locations.length') > 0) {
        var _ret = (function () {
          var filteredMenus = [];
          _this.get('_sortedMenus').forEach(function (menu) {
            var belongsToLocation = [];
            menu.get('locations').forEach(function (location) {
              if (_this.get('locations').filterBy('id', location).length > 0) {
                belongsToLocation.addObject(_this.get('locations').filterBy('id', location));
              }
            });
            if (belongsToLocation.length > 0) {
              filteredMenus.addObject(menu);
            }
          });
          return {
            v: filteredMenus.sortBy('name')
          };
        })();

        if (typeof _ret === "object") return _ret.v;
      } else if (this.get('vendors') !== null || this.get('selectedVendorManagement') !== null) {
        var _ret2 = (function () {
          var filteredMenus = [];
          if (_this.get('_filteredLocations.length') > 0) {
            _this.get('_sortedMenus').forEach(function (menu) {
              var belongsToLocation = [];
              menu.get('locations').forEach(function (location) {
                if (_this.get('_filteredLocations').filterBy('id', location).length > 0) {
                  belongsToLocation.addObject(_this.get('_filteredLocations').filterBy('id', location));
                }
              });
              if (belongsToLocation.length > 0) {
                filteredMenus.addObject(menu);
              }
            });
          }
          return {
            v: filteredMenus.sortBy('name')
          };
        })();

        if (typeof _ret2 === "object") return _ret2.v;
      } else {
        var _ret3 = (function () {
          var filteredMenus = [];
          if (_this.get('_filteredLocations.length') > 0) {
            _this.get('_sortedMenus').forEach(function (menu) {
              var belongsToLocation = [];
              menu.get('locations').forEach(function (location) {
                if (_this.get('_filteredLocations').filterBy('id', location).length > 0) {
                  belongsToLocation.addObject(_this.get('_filteredLocations').filterBy('id', location));
                }
              });
              if (belongsToLocation.length > 0) {
                filteredMenus.addObject(menu);
              }
            });
            return {
              v: filteredMenus.sortBy('name')
            };
          } else {
            return {
              v: _this.get('_sortedMenus')
            };
          }
        })();

        if (typeof _ret3 === "object") return _ret3.v;
      }
    }),
    // Filter locations list when one of the next attributes changes: 'vendors', 'selectedVendorManagement', '_locations', '_vendorCompanies'
    _filteredLocations: computed('vendors', 'selectedVendorManagement', '_locations', '_vendorCompanies', function () {
      var _this2 = this;

      if (this.get('vendors') !== null) {
        var _ret4 = (function () {
          var filteredLocations = [];
          _this2.get('_locations').forEach(function (location) {
            if (isEqual(location.companyId.toString(), _this2.get('vendors.companyId'))) {
              filteredLocations.addObject(location);
            }
          });
          return {
            v: filteredLocations.sortBy('name')
          };
        })();

        if (typeof _ret4 === "object") return _ret4.v;
      } else {
        return this.get('_locations').sortBy('name');
      }
    }),
    _locations: [],
    // Initialize component values
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('searchOptions.fromDate') === undefined) {
        this.set('searchOptions.fromDate', (0, _moment["default"])().format('MM/DD/YYYY'));
      }

      if (this.get('searchOptions.toDate') === undefined) {
        this.set('searchOptions.toDate', (0, _moment["default"])().format('MM/DD/YYYY'));
      }
      this.clearSearchCriteria();
      this.initializeTenderTypes();
      this.initializeCreditCardTypes();
      this.initializeOrderStatuses();
      this._initializeReportExportParameters();
      this._initializeUsers();
    },
    //Initialize users available to be filtered later on
    _initializeUsers: function _initializeUsers() {
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {
          var companyId = this.get('lockedCompany.id');
          this.set('allUsers', this.get('store').query('user', { companyId: companyId, pageSize: -1 }));
        } else {
          this.set('allUsers', this.get('store').query('user', { pageSize: -1 }));
        }
      } else {
        var companyId = this.get('loggedAccount.company.id');
        this.set('allUsers', this.get('store').query('user', { companyId: companyId, pageSize: -1 }));
      }
      this.set('filteredUsers', this.get('allUsers'));
    },
    // Initialize available tender types list
    initializeTenderTypes: function initializeTenderTypes() {
      var _this3 = this;

      //initialize locationGroups
      if (this.get('tenderTypes').then === undefined) {
        // no promise, lets load the data
        this.set('_availableTenders', this.get('tenderTypes'));
      } else {
        // if a promise set the content when it resolves
        this.get('tenderTypes').then(function (content) {
          run(function () {
            _this3.set('_availableTenders', content);
          });
        });
      }
    },
    // Initialize available cc types list
    initializeCreditCardTypes: function initializeCreditCardTypes() {
      var _this4 = this;

      //initialize locationGroups
      if (this.get('availableCreditCardTypes').then === undefined) {
        // no promise, lets load the data
        this.set('_ccTypes', this.get('availableCreditCardTypes'));
      } else {
        // if a promise set the content when it resolves
        this.get('availableCreditCardTypes').then(function (content) {
          run(function () {
            _this4.set('_ccTypes', content);
          });
        });
      }
    },
    // Initialize order statuses list
    initializeOrderStatuses: function initializeOrderStatuses() {
      var _this5 = this;

      //initialize locationGroups
      if (this.get('availableOrderStatuses').then === undefined) {
        // no promise, lets load the data
        this.set('_orderStatus', this.get('availableOrderStatuses'));
        this.set('selectedEntityOrderStatus', this.get('_orderStatus').findBy('id', this.get('selectedOrderStatus')));
      } else {
        // if a promise set the content when it resolves
        this.get('availableOrderStatuses').then(function (content) {
          run(function () {
            _this5.set('_orderStatus', content);
            _this5.set('selectedEntityOrderStatus', _this5.get('_orderStatus').findBy('id', _this5.get('selectedOrderStatus')));
          });
        });
      }
    },
    // Set export parameters default values
    _initializeReportExportParameters: function _initializeReportExportParameters() {
      this.set('searchOptions.reportOutput', this.get('intl').t('report.searchCriteria.reportOutput.options.csv'));
      this.set('searchOptions.isEmailDelivery', false);
      this.set('searchOptions.emails', this.get('loggedAccount.data.email'));
      this.set('searchOptions.isExport', false);
    },
    // Validate de value of the expiration date
    _validateExpirationDate: function _validateExpirationDate(expiration, isValid) {
      var errorMessage = undefined;
      var expirationArray = expiration.replace(/ /g, '').split('/');
      var year = expirationArray[1];
      if (year.length !== 2) {
        isValid = false;
        errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.errorInvalidExpirationDate'), { closeAfter: 5000 });
        this.get('errorMessages').addObject(errorMessage);
      }
      var month = parseInt(expirationArray[0]);
      if (month < 1 || month > 12) {
        isValid = false;
        errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.errorInvalidMonth'), { closeAfter: 5000 });
        this.get('errorMessages').addObject(errorMessage);
      }
      return isValid;
    },
    // Validate last four value
    _validateLastFour: function _validateLastFour(lastFourEntry, isValid) {
      var errorMessage = undefined;
      if (lastFourEntry || !isEmpty(lastFourEntry)) {
        if (lastFourEntry.length !== 4) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.errorInvalidLastFour'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
      }
      return isValid;
    },
    // Get the selected locationGroup value
    _getSingleSelectedLocationGroup: function _getSingleSelectedLocationGroup(locationGroup) {
      if (locationGroup !== null) {
        if (locationGroup.id !== undefined && locationGroup.id !== null) {
          return locationGroup;
        } else if (locationGroup.length !== undefined && locationGroup.length === 1) {
          return locationGroup.objectAt(0);
        } else {
          return null;
        }
      } else {
        if (this.get('locationGroups.id') !== undefined && this.get('locationGroups.id') !== null) {
          return this.get('locationGroups');
        } else if (this.get('locationGroups.length') !== undefined && this.get('locationGroups.length') === 1) {
          return this.get('locationGroups[0]');
        } else {
          return null;
        }
      }
    },
    clearSearchCriteria: function clearSearchCriteria() {
      this.set('locationGroups', null);
      this.get('locations').clear();
      this.get('menus').clear();
      this.set('vendors', null);
    },
    actions: {
      /**
       * This method get the values according to the selected locationGroup
       *
       * @param locationGroup selected location groups list
       */
      locationGroupSelection: function locationGroupSelection(locationGroup) {
        var _this6 = this;

        this.set('locationGroups', locationGroup);
        this.get('locations').clear();
        this.get('menus').clear();
        this.set('vendors', null);
        this.get('_vendorCompanies').clear();
        if (locationGroup !== null && locationGroup !== undefined) {
          (function () {
            var locationGroupId = locationGroup.get('id');
            var company = null;
            if (_this6.get('isBRUser')) {
              if (_this6.get('lockedCompany') !== undefined) {
                company = _this6.get('lockedCompany.id');
              }
            } else {
              company = _this6.get('loggedAccount.company.id');
            }
            var companyRole = null;
            locationGroup.get('companiesRoles').forEach(function (role) {
              if (isEqual(role.get('companyId'), company)) {
                companyRole = role.get('roleId');
              }
            });
            _this6.get('store').query('menuNameFilter', {
              companyId: company,
              roleId: companyRole,
              locationGroupId: locationGroupId
            }).then(function (content) {
              run(function () {
                _this6.set('_sortedMenus', content);
              });
            });

            _this6.get('store').queryRecord('reportsLocationGroup', {
              companyId: company,
              roleId: companyRole,
              locationGroupId: locationGroupId
            }).then(function (content) {
              run(function () {

                if (content.get('vendorCompanies.length') > 0) {
                  _this6.set('hasVendors', true);
                  _this6.set('_vendorCompanies', content.get('vendorCompanies').sortBy('companyName'));
                }

                if (content.get('vendorManagementCompanies.length') > 0) {
                  _this6.set('hasVendorManagements', true);
                  _this6.set('_vendorManagementCompanies', content.get('vendorManagementCompanies').sortBy('companyName'));
                }

                _this6.set('_locations', content.get('locations'));
                _this6.set('producerOwner', content.get('producer'));
              });
            });
          })();
        } else {
          this.get('_vendorCompanies').clear();
          this.get('_filteredMenus').clear();
          this.get('_filteredLocations').clear();
          this.get('_filteredVendorCompanies').clear();
          this.get('_locations').clear();
          this.set('producerOwner', null);
        }
      },
      vendorSelection: function vendorSelection(vendor) {
        this.set('vendors', vendor);
      },
      showExportModal: function showExportModal() {
        this._initializeReportExportParameters();
        this.set('showExportModal', true);
      },
      closeModal: function closeModal() {
        this.set('showExportModal', false);
      },
      accept: function accept(item) {
        this.set('showExportModal', false);
        this.sendAction('search', item);
        this._initializeReportExportParameters();
      },
      orderStatusSelection: function orderStatusSelection(selection) {
        this.set('selectedEntityOrderStatus', selection);
        if (selection) {
          this.set('selectedOrderStatus', selection.get('id'));
        } else {
          this.set('selectedOrderStatus', undefined);
        }
      },
      userProfileOnChange: function userProfileOnChange(selectedUserProfile) {
        var _this7 = this;

        if (selectedUserProfile.length > 0) {
          (function () {
            var tempUsers = [];
            _this7.set('userProfiles', selectedUserProfile);
            _this7.get('userProfiles').forEach(function (userProfile) {
              tempUsers.addObjects(_this7.get('allUsers').filterBy('userProfile.id', userProfile.id));
            });
            _this7.set('filteredUsers', tempUsers.sortBy('dropDownName'));
          })();
        } else {
          this.set('userProfiles', []);
          this.set('filteredUsers', this.get('allUsers').sortBy('dropDownName'));
        }
      },
      search: function search() {
        var _this8 = this;

        this._initializeReportExportParameters();
        this.set('isShowExportButton', false);

        this.send('cleanMessages');
        var isValid = true;
        var errorMessage = undefined;
        if (this.get('fromDate') === null) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.emptyFromDate'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }

        if (this.get('toDate') === null) {
          isValid = false;
          errorMessage = this.get('notify').alert(this.get('intl').t('search.errors.emptyToDate'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }

        // Set gratuities false before checking the gratuities selected
        this.set('searchOptions.tips', false);
        this.set('searchOptions.serviceFee', false);

        // Set selected gratuities values
        if (this.get('selectedGratuities.length') > 0) {
          this.get('selectedGratuities').forEach(function (gratuity) {
            if (gratuity === 0) {
              _this8.set('searchOptions.tips', true);
            } else {
              _this8.set('searchOptions.serviceFee', true);
            }
          });
        }

        if (isValid) {
          if (this.get('ccExpiration') || !isEmpty(this.get('ccExpiration'))) {
            this.get('ccExpiration').format('MM/YY');
            isValid = this._validateExpirationDate(this.get('ccExpiration').format('MM/YY'), isValid);
          }
        }

        if (isValid) {
          isValid = this._validateLastFour(this.get('lastFour'), isValid);
        }

        if (isValid) {
          this.set('page', 0);
          this.set('_canLoadMore', true);
          this.sendAction('search', this.get('searchOptions'));
        }
      }
    }
  });
});