define("fcs-dashboard/pods/location-group/edit/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin) {
  var isEqual = _ember["default"].isEqual;
  exports["default"] = _ember["default"].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], {
    renderTemplate: function renderTemplate() {
      this.render('locationGroup/edit', { into: 'application' });
    },
    model: function model(params) {
      this.store.unloadAll('promotionImage');
      return this.store.findRecord('locationGroup', params.locationGroup_id, { reload: true });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('saveEnabled', true);
      controller.set('saving', false);
      controller.set('authToken', this.get('session.data.authenticated.access_token'));
    },
    actions: {
      willTransition: function willTransition() {
        this.send('cleanMessages');
        this.controller.get('model.customFees').forEach(function (customFee) {
          if (customFee) {
            customFee.rollbackAttributes();
          }
        });
        this.get('currentModel').rollbackAttributes();
      },
      cancel: function cancel(item) {
        this.send('cleanMessages');
        item.get('promotions').forEach(function (promotion) {
          promotion.get('images').forEach(function (image) {
            image.rollbackAttributes();
          });
        });
        item.rollbackAttributes();
        this.transitionTo('locationGroup');
      },
      save: function save(item) {
        var _this = this;

        var existsProducerOwner = false;
        var validCredentials = true;
        this.send('cleanMessages');

        // validate descriptor
        if (item.get('descriptorEnabled')) {
          var dynamicDescriptor = item.get('dynamicDescriptor');
          if (dynamicDescriptor === '') {
            validCredentials = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.descriptorEmptyError'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }

        //validate companyRoles
        item.get('companiesRoles').forEach(function (companyRole) {
          if (!companyRole.get('validations.isValid')) {
            validCredentials = false;
            companyRole.get('validations.errors').forEach(function (error) {
              var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
              _this.get('errorMessages').addObject(errorMessage);
            });
          }

          if (isEqual(companyRole.get('roleId'), '13')) {
            existsProducerOwner = true;
          }
        });

        if (!existsProducerOwner) {
          validCredentials = false;
          var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.noProducerOwnerSelected'), { closeAfter: 5000 });
          this.get('errorMessages').addObject(errorMessage);
        }
        if (item.get('isCashless')) {
          if (item.get('cashlessInformation.gateway') === null || item.get('cashlessInformation.gateway') === undefined || item.get('cashlessInformation.gateway') === 0) {
            validCredentials = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.gatewayError'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
          var codeLength = parseInt(item.get('cashlessInformation.humanReadableCodeLength'));
          if (isNaN(codeLength) || codeLength <= 0) {
            validCredentials = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.cashless.humanReadableCodeLength.error'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }
        if (item.get('locationGroupType.content.data.name') === 'Event') {
          if (item.get('startDate') === null) {
            validCredentials = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.startDateError'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
          if (item.get('endDate') === null) {
            validCredentials = false;
            var errorMessage = this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.endDateError'), { closeAfter: 5000 });
            this.get('errorMessages').addObject(errorMessage);
          }
        }

        if (item.get('validations.isValid') && validCredentials) {
          this.controller.set('saving', true);
          item.save().then(function () {
            _this.controller.set('saving', false);
            _this.get('notify').success(_this.get('intl').t('locationGroups.details.errors.successSave', {
              locationGroupType: item.get('locationGroupType.name'),
              name: item.get('name')
            }));
            _this.transitionTo('locationGroup');
          })["catch"](function (error) {
            _this.controller.set('saving', false);
            if (error !== undefined) {
              message = "locationGroups.details.errors." + error.errors.get(0).detail;
            }
            var message = 'locationGroups.details.errors.savingError';
            if (error.message && error.message.includes('image')) {
              message = 'locationGroups.details.errors.imageError';
            }
            var errorMessage = _this.get('notify').alert(_this.get('intl').t(message, {
              locationGroupType: item.get('locationGroupType.name'),
              name: item.get('name')
            }), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        } else {
          this.controller.set('saveEnabled', true);
          item.get('validations.errors').forEach(function (error) {
            var errorMessage = _this.get('notify').alert(error.get('message'), { closeAfter: 5000 });
            _this.get('errorMessages').addObject(errorMessage);
          });
        }
      }
    }
  });
});