define("fcs-dashboard/pods/components/financial-overview-report/financial-report-result/financial-report/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "moment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _moment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsBaseComponent["default"], {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isShowingChartPie', true);
      this.set('isShowingDollar', true);

      this.set('isShowingCharts', true);

      var d = this.data;
      var summarizeBy = d['summarizeBy'];
      if (summarizeBy === '2') {

        var fromDateString = d['query']['fromDate'].replace('-', '/');
        var toDateString = d['query']['toDate'].replace('-', '/');

        var from = (0, _moment["default"])(fromDateString);
        var to = (0, _moment["default"])(toDateString);
        var diff = to.diff(from, 'days');

        if (diff > 1) {
          this.set('isShowingCharts', false);
        }
      }
    },
    hideCompanyColumn: computed(function () {
      var d = this.data;
      var summarizeBy = d['summarizeBy'];
      if (summarizeBy === '3' || summarizeBy === undefined || summarizeBy === '5') {
        return false;
      } else {
        return true;
      }
    }),
    dateMenuColumnTitle: computed(function () {
      var d = this.data;
      var summarizeBy = this.summarizeBy || d.summarizeBy;
      if (summarizeBy === '3' || summarizeBy === '4') {
        return 'Menu';
      } else if (summarizeBy === '5') {
        return 'Location';
      } else {
        return 'Date';
      }
    }),
    summarizeBy: null,
    actions: {
      selectChartPie: function selectChartPie() {
        this.set('isShowingChartPie', true);
        this.set('isShowingChartBar', false);
        this.set('isShowingChartLine', false);
        this.$('#btn-chart-type-pie').addClass('selected');
        this.$('#btn-chart-type-bar').removeClass('selected');
        this.$('#btn-chart-type-line').removeClass('selected');
      },
      selectChartBar: function selectChartBar() {
        this.set('isShowingChartPie', false);
        this.set('isShowingChartBar', true);
        this.set('isShowingChartLine', false);
        this.$('#btn-chart-type-pie').removeClass('selected');
        this.$('#btn-chart-type-bar').addClass('selected');
        this.$('#btn-chart-type-line').removeClass('selected');
      },
      selectChartLine: function selectChartLine() {
        this.set('isShowingChartPie', false);
        this.set('isShowingChartBar', false);
        this.set('isShowingChartLine', true);
        this.$('#btn-chart-type-pie').removeClass('selected');
        this.$('#btn-chart-type-bar').removeClass('selected');
        this.$('#btn-chart-type-line').addClass('selected');
      },
      selectChartDollar: function selectChartDollar() {
        this.set('isShowingDollar', true);
        this.set('isShowingPercent', false);
        this.set('isShowingNumber', false);
        this.$('#btn-chart-value-dollar').addClass('selected');
        this.$('#btn-chart-value-percent').removeClass('selected');
        this.$('#btn-chart-value-number').removeClass('selected');
        this.$('#chart_label').html('Gross Sales');
      },
      selectChartPercent: function selectChartPercent() {
        this.set('isShowingDollar', false);
        this.set('isShowingPercent', true);
        this.set('isShowingNumber', false);
        this.$('#btn-chart-value-dollar').removeClass('selected');
        this.$('#btn-chart-value-percent').addClass('selected');
        this.$('#btn-chart-value-number').removeClass('selected');
        this.$('#chart_label').html('Gross Sales Percentage');
      },
      selectChartNumber: function selectChartNumber() {
        this.set('isShowingDollar', false);
        this.set('isShowingPercent', false);
        this.set('isShowingNumber', true);
        this.$('#btn-chart-value-dollar').removeClass('selected');
        this.$('#btn-chart-value-percent').removeClass('selected');
        this.$('#btn-chart-value-number').addClass('selected');
        this.$('#chart_label').html('Transactions');
      }
    },
    data: null,
    _chartDollarOptions: computed(function () {
      return {
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function title(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label: function label(tooltipItem, data) {
              return '$' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            }
          }
        },
        legend: {
          display: false
        }
      };
    }),
    _chartPercentOptions: computed(function () {
      return {
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function title(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label: function label(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
            }
          }
        },
        legend: {
          display: false
        }
      };
    }),
    _chartNumberOptions: computed(function () {
      return {
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function title(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label: function label(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' Transactions';
            }
          }
        },
        legend: {
          display: false
        }
      };
    }),
    chartNumberData: function chartNumberData() {
      var d = this.data;

      var cData = [];
      var cLabels = [];

      //Loop through and get the raw data for the charts from the rows
      var i = 0;
      var details = d.get('financialReportDetails');
      details.forEach(function (element) {
        if (i < details.length - 2) {
          var row = element._data;
          cLabels.push(row['date']);
          var count = row['cashTransactionCount'] + row['creditTransactionCount'] + row['rfidTransactionCount'];
          cData.push(count);
        }
        i++;
      });

      return { 'labels': cLabels, 'data': cData };
    },
    chartPercentData: function chartPercentData() {
      var d = this.data;

      var cData = [];
      var cLabels = [];

      var total = 0;
      var i = 0;
      var details = d.get('financialReportDetails');
      details.forEach(function (element) {
        if (i < details.length - 2) {
          var row = element._data;
          total += row['grossRevenue'];
        }
        i++;
      });

      //Loop through and get the raw data for the charts from the rows
      i = 0;
      details.forEach(function (element) {
        if (i < details.length - 2) {
          var row = element._data;
          var val = row['grossRevenue'] * 100.0 / total;
          cData.push(val.toFixed(2));
          cLabels.push(row['date']);
        }
        i++;
      });

      return { 'labels': cLabels, 'data': cData };
    },
    chartDollarData: function chartDollarData() {

      var d = this.data;

      var cData = [];
      var cLabels = [];
      var cDataTips = [];

      //Loop through and get the raw data for the charts from the rows
      var i = 0;
      var details = d.get('financialReportDetails');
      details.forEach(function (element) {
        if (i < details.length - 2) {
          var row = element._data;
          cData.push(row['grossRevenue']);
          cLabels.push(row['date']);
          cDataTips.push(row['creditCardTips']);
        }
        i++;
      });

      return { 'labels': cLabels, 'data': cData };
    },
    _lineChartDollarData: computed(function () {

      var chartData = this.chartDollarData();
      var colors = rainbow(2, 0);

      return {
        labels: chartData['labels'],
        datasets: [{
          label: 'Gross Sales',
          data: chartData['data'],
          backgroundColor: colors[0],
          borderColor: colors[1],
          borderWidth: 1
        }]
      };
    }),
    _lineChartPercentData: computed(function () {

      var chartData = this.chartPercentData();
      var colors = rainbow(2, 0);

      return {
        labels: chartData['labels'],
        datasets: [{
          label: 'Gross Sales',
          data: chartData['data'],
          backgroundColor: colors[0],
          borderColor: colors[1],
          borderWidth: 1
        }]
      };
    }),
    _lineChartNumberData: computed(function () {

      var chartData = this.chartNumberData();
      var colors = rainbow(2, 0);

      return {
        labels: chartData['labels'],
        datasets: [{
          label: 'Gross Sales',
          data: chartData['data'],
          backgroundColor: colors[0],
          borderColor: colors[1],
          borderWidth: 1
        }]
      };
    }),
    _chartDollarData: computed(function () {

      var chartData = this.chartDollarData();
      var cBackColors = [];
      var cBordColors = [];

      var i = 0;
      chartData['labels'].forEach(function () {
        var colors = rainbow(chartData['labels'].length, i);
        cBackColors.push(colors[0]);
        cBordColors.push(colors[1]);
        i++;
      });

      return {
        labels: chartData['labels'],
        datasets: [{
          label: 'Gross Sales',
          data: chartData['data'],
          backgroundColor: cBackColors,
          borderColor: cBordColors,
          borderWidth: 1
        }]
      };
    }),
    _chartPercentData: computed(function () {

      var chartData = this.chartPercentData();
      var cBackColors = [];
      var cBordColors = [];

      var i = 0;
      chartData['labels'].forEach(function () {
        var colors = rainbow(chartData['labels'].length, i);
        cBackColors.push(colors[0]);
        cBordColors.push(colors[1]);
        i++;
      });

      return {
        labels: chartData['labels'],
        datasets: [{
          label: 'Gross Sales',
          data: chartData['data'],
          backgroundColor: cBackColors,
          borderColor: cBordColors,
          borderWidth: 1
        }]
      };
    }),
    _chartNumberData: computed(function () {

      var chartData = this.chartNumberData();
      var cBackColors = [];
      var cBordColors = [];

      var i = 0;
      chartData['labels'].forEach(function () {
        var colors = rainbow(chartData['labels'].length, i);
        cBackColors.push(colors[0]);
        cBordColors.push(colors[1]);
        i++;
      });

      return {
        labels: chartData['labels'],
        datasets: [{
          label: 'Gross Sales',
          data: chartData['data'],
          backgroundColor: cBackColors,
          borderColor: cBordColors,
          borderWidth: 1
        }]
      };
    }),
    _columns: computed(function () {
      return [{
        title: this.get('dateMenuColumnTitle'),
        className: 'date-cell',
        propertyName: 'date',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'company',
        disableSorting: true,
        isHidden: this.get('hideCompanyColumn')
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashSales'),
        className: 'sales-by-product-cell',
        propertyName: 'cashSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.creditSales'),
        className: 'sales-by-product-cell',
        propertyName: 'creditCardPresentSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashlessSales'),
        className: 'sales-by-product-cell',
        propertyName: 'openLoopSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.totalSales'),
        className: 'sales-by-product-cell',
        propertyName: 'totalSales',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'tax',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'voids',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'tokenDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'cashDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'couponDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'digitalDiscount',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'discounts',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'refunds',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'creditCardTips',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashlessTips'),
        className: 'sales-by-product-cell',
        propertyName: 'openLoopTips',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        className: 'sales-by-product-cell',
        propertyName: 'digitalTips',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.customFees'),
        className: 'sales-by-product-cell',
        propertyName: 'customFeeTotal',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.Surcharges'),
        className: 'sales-by-product-cell',
        propertyName: 'surchargeTotal',
        template: 'financialOverviewReport/amountCell',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashPaymentCount'),
        className: 'centerContent',
        propertyName: 'cashOrderCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.creditPaymentCount'),
        className: 'centerContent',
        propertyName: 'creditOrderCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.cashlessPaymentCount'),
        className: 'centerContent',
        propertyName: 'openLoopOrderCount',
        disableSorting: true
      }, {
        title: this.get('intl').t('report.result.financialOverviewReport.headers.totalOrderCount'),
        className: 'centerContent',
        propertyName: 'totalOrderCount',
        disableSorting: true
      }, {
        className: 'centerContent',
        propertyName: 'tabletCount',
        disableSorting: true
      }];
    })
  });
});