define("fcs-dashboard/pods/search-order/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/base-component", "moment"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent, _moment) {
  var Route = _ember["default"].Route;
  var isEmpty = _ember["default"].isEmpty;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    setupController: function setupController(controller) {
      controller.set('searchOptions', this.get('accountSession.searchOptions'));
      controller.set('authToken', this.get('session.data.authenticated.access_token'));
      controller.set('page', 1);
      controller.set('pageSize', 10);
      controller.set('orders', []);
      controller.set('isLoading', false);
      controller.set('isShowExportButton', false);
      controller.set('totalResults', 0);
      controller.set('totalAmount', 0);
    },
    actions: {
      willTransition: function willTransition(transition) {
        window.stop();
        if (!this._isValidTransition(transition.targetName)) {
          Logger.debug('Aborting transition invalid route');
          this.transitionTo('index');
        } else {
          return true;
        }
      },
      search: function search(queryOptions) {
        this.controller.set('page', 1);
        this.controller.set('orders', []);
        this.send('fetchRecords', queryOptions);
      },
      fetchRecords: function fetchRecords(queryOptions) {
        var _this = this;

        var query = queryOptions.getProperties(['searchText', 'lastName', 'lastFour', 'ccExpiration', 'tabName', 'cashlessTag', 'selectedOrderStatus', 'selectedTenders', 'selectedCCTypes', 'priceStatuses', 'orderId', 'orderNote', 'cashUid', 'accessLevel', 'reference', 'authCode', 'product', 'serviceFee', 'tips', 'surcharge']);
        //set property to TRUE
        this.controller.toggleProperty('isLoading');
        //add page and limit into query
        query['page'] = this.controller.get('page');
        query['pageSize'] = this.controller.get('pageSize');
        //get to and from dates
        query['fromDate'] = (0, _moment["default"])(queryOptions.get('fromDate')).format("MM-DD-YYYY").toString();
        query['toDate'] = (0, _moment["default"])(queryOptions.get('toDate')).format("MM-DD-YYYY").toString();

        if (queryOptions.get('selectedTenders') !== null && queryOptions.get('selectedTenders') !== undefined) {
          var selectedTenders = queryOptions.get('selectedTenders').join(',');
          if (selectedTenders) {
            query['selectedTenders'] = selectedTenders;
          }
        }

        if (queryOptions.get('selectedCCTypes') !== null && queryOptions.get('selectedCCTypes') !== undefined) {
          var selectedCCTypes = queryOptions.get('selectedCCTypes').join(',');
          if (selectedCCTypes) {
            query['selectedCCTypes'] = selectedCCTypes;
          }
        }

        if (queryOptions.get('priceStatuses') !== null && queryOptions.get('priceStatuses') !== undefined) {
          var priceStatuses = queryOptions.get('priceStatuses').join(',');
          if (priceStatuses) {
            query['priceStatuses'] = priceStatuses;
          }
        }

        // Get ids from lists of objects
        if (queryOptions.get('gateways') !== null && queryOptions.get('gateways') !== undefined) {
          var gateways = queryOptions.get('gateways').mapBy('id').join(',');
          if (gateways) {
            query['gateways'] = gateways;
          }
        }
        if (queryOptions.get('users') !== null && queryOptions.get('paymentModes') !== undefined) {
          var users = queryOptions.get('users').mapBy('id').join(',');
          if (users) {
            query['users'] = users;
          }
        }
        if (queryOptions.get('orderStatuses') !== null && queryOptions.get('orderStatuses') !== undefined) {
          var orderStatuses = queryOptions.get('orderStatuses').mapBy('id').join(',');
          if (orderStatuses) {
            query['orderStatuses'] = orderStatuses;
          }
        }
        if (queryOptions.get('userProfiles') !== null && queryOptions.get('userProfiles') !== undefined) {
          var userProfiles = queryOptions.get('userProfiles').mapBy('id').join(',');
          if (userProfiles) {
            query['userProfiles'] = userProfiles;
          }
        }
        if (queryOptions.get('locationGroups') !== null && queryOptions.get('locationGroups') !== undefined) {
          // TODO Modify this parameter once the multiple selection for locationGroups is enabled
          query['locationGroups'] = queryOptions.get('locationGroups.id');
        }
        if (queryOptions.get('locations') !== null && queryOptions.get('locations') !== undefined) {
          var locations = queryOptions.get('locations').mapBy('id').join(',');
          if (locations) {
            query['locations'] = locations;
          }
        }
        if (queryOptions.get('vendors') !== null && queryOptions.get('vendors') !== undefined) {
          // TODO Modify this parameter once the multiple selection for vendors is enabled
          var vendors = [];
          vendors.addObject(queryOptions.get('vendors.companyId'));
          if (vendors.length > 0) {
            query['vendors'] = vendors.join(',');
          }
        }
        if (queryOptions.get('menus') !== null && queryOptions.get('menus') !== undefined) {
          var menus = queryOptions.get('menus').mapBy('id').join(',');
          if (menus) {
            query['menus'] = menus;
          }
        }
        if (queryOptions.get('productTypes') !== null && queryOptions.get('productTypes') !== undefined) {
          var productTypes = queryOptions.get('productTypes').mapBy('id').join(',');
          if (productTypes) {
            query['productTypes'] = productTypes;
          }
        }
        if (queryOptions.get('tabStatuses') !== null && queryOptions.get('tabStatuses') !== undefined) {
          var tabStatuses = queryOptions.get('tabStatuses').mapBy('id').join(',');
          if (tabStatuses) {
            query['tabStatuses'] = tabStatuses;
          }
        }
        if (queryOptions.get('paymentModes') !== null && queryOptions.get('paymentModes') !== undefined) {
          var paymentModes = queryOptions.get('paymentModes').mapBy('id').join(',');
          if (paymentModes) {
            query['paymentModes'] = paymentModes;
          }
        }
        if (queryOptions.get('paymentStatuses') !== null && queryOptions.get('paymentStatuses') !== undefined) {
          var paymentStatuses = queryOptions.get('paymentStatuses').mapBy('id').join(',');
          if (paymentStatuses) {
            query['paymentStatuses'] = paymentStatuses;
          }
        }

        if (isEmpty(query['lastFour'])) {
          query['lastFour'] = undefined;
        }

        if (isEmpty(query['ccExpiration'])) {
          query['ccExpiration'] = undefined;
        } else {
          query['ccExpiration'] = (0, _moment["default"])(queryOptions.get('ccExpiration')).format("MM/YY").toString();
        }

        if (isEmpty(query['cashlessTag'])) {
          query['cashlessTag'] = undefined;
        }

        if (isEmpty(query['tabName'])) {
          query['tabName'] = undefined;
        }

        if (isEmpty(query['authCode'])) {
          query['authCode'] = undefined;
        }

        if (isEmpty(query['orderNote'])) {
          query['orderNote'] = undefined;
        }

        if (isEmpty(query['cashUid'])) {
          query['cashUid'] = undefined;
        }

        if (isEmpty(query['lastName'])) {
          query['lastName'] = undefined;
        }

        if (isEmpty(query['gateways'])) {
          query['gateways'] = undefined;
        }

        //send company id when user is BR and he is locked to a company
        if (this.get('isBRUser') && this.get('lockedCompany') !== undefined) {
          query['company'] = this.get('lockedCompany.id');
        }

        query['reportOutput'] = queryOptions.get('reportOutput');
        query['isEmailDelivery'] = queryOptions.get('isEmailDelivery');
        query['emails'] = queryOptions.get('emails');
        query['isExport'] = queryOptions.get('isExport');

        this.store.query('searchOrder', query).then(function (orders) {
          if (query['isExport']) {
            (function () {
              var endpoint = orders.get('meta.outputFileName');
              var _authToken = _this.controller.get('authToken');
              var options = {
                url: endpoint,
                type: 'GET',
                xhrFields: {
                  responseType: 'blob'
                }
              };
              options.beforeSend = function (xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
              };
              options.success = function (result, status, response) {
                if (query['isEmailDelivery']) {
                  _this.get('notify').success(_this.get('intl').t('report.dialog.emailSent'));
                } else {
                  var blob = undefined;
                  if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.csv')) {
                    blob = new Blob([result], { type: "text/csv" });
                  } else if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.pdf')) {
                    blob = new Blob([result], { type: "application/pdf" });
                  }

                  var contentDispositionHeader = response.getResponseHeader('Content-Disposition');
                  var filename = contentDispositionHeader.split(';')[1].trim().split('=')[1];
                  window.saveAs(blob, filename);
                }
              };
              options.error = function () {
                _this.controller.set('isLoading', false);

                console.log('error');
                if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.csv')) {
                  _this.get('notify').alert(_this.get('intl').t('report.result.errors.csvFileError'), { closeAfter: 5000 });
                } else if (query['reportOutput'] === _this.get('intl').t('report.searchCriteria.reportOutput.options.pdf')) {
                  _this.get('notify').alert(_this.get('intl').t('report.result.errors.pdfFileError'), { closeAfter: 5000 });
                }
              };
              _ember["default"].$.ajax(options);
            })();
          }
          if (orders.get('meta.total') === 0) {
            _this.controller.set('isShowExportButton', false);
          } else {
            _this.controller.set('isShowExportButton', true);
          }
          _this.controller.set('totalResults', orders.get('meta.total'));
          _this.controller.set('totalAmount', orders.get('meta.totalAmount'));
          _this.controller.set('orders', orders);
          _this.controller.incrementProperty('page');
          //set property to FALSE
          _this.controller.set('isLoading', false);
        });
      }
    }
  });
});