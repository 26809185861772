define("fcs-dashboard/pods/location/route", ["exports", "ember", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/mixins/common-mixin", "fcs-dashboard/mixins/base-component"], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsCommonMixin, _fcsDashboardMixinsBaseComponent) {
  var Route = _ember["default"].Route;
  var Logger = _ember["default"].Logger;
  exports["default"] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsCommonMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    model: function model() {
      Logger.debug('getting data!');
      var company = undefined;
      if (this.get('lockedCompany') !== undefined) {
        company = this.get('lockedCompany.id');
      } else if (!this.get('isBRUser')) {
        company = this.get('loggedAccount.company.id');
      }
      if (company) {
        return this.store.query('location', { companyId: company });
      } else {
        return this.store.query('location', { translatedStatus: 'Active' });
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      },
      save: function save() {
        this.send('reloadLocations');
      },
      reloadLocations: function reloadLocations() {
        var self = this;
        var company = undefined,
            model = undefined;
        if (this.get('lockedCompany') !== undefined) {
          company = this.get('lockedCompany.id');
        } else if (!this.get('isBRUser')) {
          company = this.get('loggedAccount.company.id');
        }
        if (company) {
          model = this.store.query('location', { companyId: company });
        } else {
          model = this.store.query('location', { simple: true });
        }
        model.then(function (result) {
          self.controller.set('model', result);
        });
      },

      toggleLocationsActive: function toggleLocationsActive(locationIds, activate) {
        var _this = this;

        var endpoint = "/api/v1/locations/toggleLocationsActive";
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'POST',
          data: { locationIds: locationIds,
            activate: activate }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function () {
          Logger.debug('Locations toggle');
          _this.send('cleanMessages');
          _this.send('reloadLocations');
        };
        options.error = function () {
          _this.get('errorMessages').addObject(_this.get('notify').alert(_this.get('intl').t('menu.builder.steps.stepSix.errors.saveError'), { closeAfter: 5000 }));
        };
        _ember["default"].$.ajax(options);
      }
    }
  });
});