define('fcs-dashboard/pods/financial-overview-report/financial-report-details/model', ['exports', 'model-fragments/fragment', 'ember-data/attr'], function (exports, _modelFragmentsFragment, _emberDataAttr) {
  exports['default'] = _modelFragmentsFragment['default'].extend({
    name: (0, _emberDataAttr['default'])('string'),
    timezone: (0, _emberDataAttr['default'])('string'),
    date: (0, _emberDataAttr['default'])('string'),
    company: (0, _emberDataAttr['default'])('string'),
    cashSales: (0, _emberDataAttr['default'])('number'),
    creditCardPresentSales: (0, _emberDataAttr['default'])('number'),
    openLoopSales: (0, _emberDataAttr['default'])('number'),
    totalSales: (0, _emberDataAttr['default'])('number'),
    tax: (0, _emberDataAttr['default'])('number'),
    voids: (0, _emberDataAttr['default'])('number'),
    tokenDiscount: (0, _emberDataAttr['default'])('number'),
    cashDiscount: (0, _emberDataAttr['default'])('number'),
    couponDiscount: (0, _emberDataAttr['default'])('number'),
    digitalDiscount: (0, _emberDataAttr['default'])('number'),
    discounts: (0, _emberDataAttr['default'])('number'),
    refunds: (0, _emberDataAttr['default'])('number'),
    creditCardTips: (0, _emberDataAttr['default'])('number'),
    openLoopTips: (0, _emberDataAttr['default'])('number'),
    digitalTips: (0, _emberDataAttr['default'])('number'),
    cashOrderCount: (0, _emberDataAttr['default'])('number'),
    creditOrderCount: (0, _emberDataAttr['default'])('number'),
    openLoopOrderCount: (0, _emberDataAttr['default'])('number'),
    totalOrderCount: (0, _emberDataAttr['default'])('number'),
    tabletCount: (0, _emberDataAttr['default'])('number'),
    customFeeTotal: (0, _emberDataAttr['default'])('number'),
    surchargeTotal: (0, _emberDataAttr['default'])('number')
  });
});