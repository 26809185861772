define('fcs-dashboard/pods/gateway/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'fcs-dashboard/mixins/base-component', 'ember-data', 'ember-data-preload'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardMixinsBaseComponent, _emberData, _emberDataPreload) {
  var isEmpty = _ember['default'].isEmpty;
  var PromiseObject = _emberData['default'].PromiseObject;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _fcsDashboardMixinsBaseComponent['default'], {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        var accountId = this.get('session.data.authenticated.username');
        if (!isEmpty(accountId)) {
          return PromiseObject.create({
            promise: (0, _emberDataPreload['default'])(this.get('store').queryRecord('user', { username: accountId }), [{ company: 'locations' }, 'userType'])
          });
        }
        this.store.findAll('processor');
      }
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (!this.get('isBRUser')) {
        this.transitionTo('');
      }
    },
    model: function model() {
      return this.store.query('gateway', { processorType: 1 });
    },
    actions: {
      willTransition: function willTransition() {
        window.stop();
        return true;
      }
    }
  });
});