define("fcs-dashboard/pods/components/product-catalog/list/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "fcs-dashboard/mixins/table-component", "fcs-dashboard/mixins/product-catalog-mixin", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _fcsDashboardMixinsTableComponent, _fcsDashboardMixinsProductCatalogMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var isArray = _ember["default"].isArray;
  var run = _ember["default"].run;
  var A = _ember["default"].A;
  var Logger = _ember["default"].Logger;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], _fcsDashboardMixinsTableComponent["default"], _fcsDashboardMixinsProductCatalogMixin["default"], {
    saveDisabled: false,
    tagName: '',
    selectedModels: A(),
    selectedCount: computed('selectedModels.length', function () {
      return this.get('selectedModels.length') || '0';
    }),
    columns: computed(function () {
      var hideCompanyColumn = !this.get('isBRUser');
      return [{
        template: "meta-product-catalog/actions/row-select-checkbox",
        useFilter: false,
        mayBeHidden: false,
        templateForSortCell: "meta-product-catalog/actions/row-select-all-checkbox"
      }, {
        title: '',
        template: "product-catalog/edit-action",
        className: 'editRow'
      }, {
        title: '',
        template: "product-catalog/archive-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('productCatalog.list.product'),
        filteredBy: 'catalogName'
      }, {
        propertyName: 'productType.name',
        title: this.get('intl').t('productCatalog.list.productType'),
        filteredBy: 'typeName'
      }, {
        propertyName: 'suggestedPrice',
        title: this.get('intl').t('productCatalog.list.suggestedPrice'),
        filteredBy: 'suggestedPrice',
        template: "product-catalog/suggested-price-cell",
        className: 'suggestedPriceRow'
      }, {
        propertyName: 'translatedStatus',
        title: this.get('intl').t('menu.list.table.status.header'),
        filterWithSelect: true,
        predefinedFilterOptions: this.get('catalogStatuses'),
        filterString: 'Active',
        className: 'stateRow',
        filterFunction: function filterFunction(cell, neededStr, obj) {
          return isEqual(obj.get('translatedStatus').toLowerCase(), neededStr);
        }
      }, {
        propertyName: 'companyName',
        title: this.get('intl').t('productCatalog.list.company'),
        filteredBy: 'companyName',
        isHidden: hideCompanyColumn
      }, {
        propertyName: 'displayClassName',
        title: this.get('intl').t('productCatalog.list.productCatalogClass'),
        filteredBy: 'productClass',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('productClasses')
      }, {
        propertyName: 'sku',
        title: this.get('intl').t('productCatalog.list.productCatalogSku'),
        filteredBy: 'sku'
      }, {
        title: '',
        template: "product-catalog/delete-action",
        className: 'editRow'
      }];
    }),
    _attributeSets: computed(function () {
      if (this.get('isBRUser')) {
        if (this.get('lockedCompany') !== undefined) {

          var company = this.get('lockedCompany.id');
          return this.get('store').query('attributeSet', { companyId: company });
        } else {

          return this.get('store').query('attributeSet', {});
        }
      } else {
        var company = this.get('loggedAccount.company.id');
        return this.get('store').query('attributeSet', { companyId: company });
      }
    }),
    _productClasses: computed(function () {
      return this.get('store').findAll('productClass');
    }),
    showEditModal: false,
    showMetaProductCatalogModal: false,
    currentProduct: undefined,
    _checkCompanyLock: observer('lockedCompany', function () {
      Logger.debug('calling refresh');
      this.sendAction('refreshAction');
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('selectedModels', null);

      var company = this.get('isBRUser') && this.get('lockedCompany') !== undefined ? this.get('lockedCompany') : this.get('loggedAccount.company');

      var params = {
        page: 1,
        pageSize: 10,
        translatedStatus: 'Active', // default selection
        companyId: company.id
      };

      var temp = this.get('store').query('combinedProductCatalog', params);
      if (temp.then !== undefined) {
        temp.then(function (content) {
          _this.set('catalogs', content);
          _this.set('loadReady', true);
        });
      }
    },
    actions: {
      deactivateProduct: function deactivateProduct(record) {
        this.send('toggleArchive', [record], true);
      },
      activateProduct: function activateProduct(record) {
        this.send('toggleArchive', [record], false);
      },
      deactivateBulk: function deactivateBulk() {
        var records = this.get('selectedModels');
        this.send('toggleArchive', records, true);
      },
      activateBulk: function activateBulk() {
        var records = this.get('selectedModels');
        this.send('toggleArchive', records, false);
      },
      toggleArchive: function toggleArchive(records, archive) {
        var _this2 = this;

        console.log('toggleArchive', records, archive);

        var request = function request(endpoint, ids) {
          return new Promise(function (resolve, reject) {
            if (ids.length === 0) {
              return resolve();
            }

            var options = {
              url: endpoint,
              type: 'POST',
              data: {
                ids: ids,
                archive: archive
              }
            };

            var token = _this2.get('session.data.authenticated.access_token');
            options.beforeSend = function (xhr) {
              return xhr.setRequestHeader('Authorization', "Bearer " + token);
            };

            options.success = function () {
              return resolve();
            };
            options.error = function () {
              return reject();
            };

            // noinspection JSUnresolvedReference
            _ember["default"].$.ajax(options);
          });
        };

        var nonTplIDs = [];
        var tplIDs = [];

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = records[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var record = _step.value;

            if (record.get('productCatalogTemplate')) {
              tplIDs.push(record.get('id'));
            } else {
              nonTplIDs.push(record.get('id'));
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator["return"]) {
              _iterator["return"]();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        console.log('nonTplIDs', nonTplIDs);
        console.log('tplIDs', tplIDs);

        Promise.all([request('/api/v1/productCatalogs/toggleArchive', nonTplIDs), request('/api/v1/metaProductCatalogs/toggleArchive', tplIDs)]).then(function () {
          Logger.debug('Toggled');
          _this2.sendAction('refreshAction');
        })["catch"](function () {
          var msg = _this2.get('intl').t('productCatalog.list.errors.toggleArchiveError');
          _this2.get('errorMessages').addObject(_this2.get('notify').alert(msg, { closeAfter: 5000 }));
        });
      },
      addProduct: function addProduct() {
        this.set('saveDisabled', false);
        var companyId = undefined;
        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            companyId = this.get('lockedCompany.id');
          }
        } else {
          companyId = this.get('loggedAccount.company.id');
        }

        this.set('currentProduct', this.get('store').createRecord('productCatalog', {
          companyId: companyId,
          disableValidateCompany: false
        }));
        this.set('showEditModal', true);
      },
      editProduct: function editProduct(item) {
        var _this3 = this;

        console.log('deleteProduct', item);
        this.set('saveDisabled', false);

        if (item.get('productCatalogTemplate')) {
          (function () {
            // meta product catalog
            item = _this3.get('store').findRecord('metaProductCatalog', item.get('id'));

            var handler = function handler(record) {
              run(function () {
                if (isArray(record.get('attributeSets'))) {
                  _this3.set('currentMetaProductCatalog', record);
                  _this3.set('showMetaProductCatalogModal', true);
                }
              });
              // jQuery('#j-table-filter-button').trigger('click')
            };

            if (item.then) {
              item.then(function (record) {
                return handler(record);
              });
            } else {
              handler(item);
            }
          })();
        } else {
          (function () {
            // product catalog
            item = _this3.get('store').findRecord('productCatalog', item.get('id'));

            var handler = function handler(record) {
              run(function () {
                _this3.set('currentProduct', record);
                _this3.set('showEditModal', true);
              });
              // jQuery('#j-table-filter-button').trigger('click')
            };

            if (item.then) {
              item.then(function (record) {
                return handler(record);
              });
            } else {
              handler(item);
            }
          })();
        }
      },
      closeModal: function closeModal() {
        if (!this.get('saveDisabled')) {
          this.send('cleanMessages');
          this.set('showEditModal', false);
          this.get('currentProduct.comboSelectionCatalogs').forEach(function (selection) {
            selection.get('comboSelectionProductCatalogList').forEach(function (selectionProduct) {
              selectionProduct.rollbackAttributes();
            });
            selection.rollbackAttributes();
          });
          this.get('currentProduct').rollbackAttributes();
        }
      },
      closeMetaProductCatalogModal: function closeMetaProductCatalogModal() {
        if (!this.get('saveDisabled')) {
          this.send('cleanMessages');
          this.set('showMetaProductCatalogModal', false);
          this.get('currentMetaProductCatalog').rollbackAttributes();
        }
      },
      accept: function accept() {
        var _this4 = this;

        var isValid = true;
        if (this.get('currentProduct.comboSelectionCatalogs.length') > 0) {
          this.get('currentProduct.comboSelectionCatalogs').forEach(function (selection) {
            if (selection.get('comboSelectionProductCatalogList.length') === 0) {
              isValid = false;
            }
          });
        }

        if (isValid) {
          if (this.get('currentProduct.validations.isValid')) {
            this.set('saveDisabled', true);

            //Trims name before saving
            var _name = this.get('currentProduct.name').trim();
            this.set('currentProduct.name', _name);

            this.sendAction('save', this.get('currentProduct'));
          } else {
            this.send('cleanMessages');
            this.get('currentProduct.validations.errors').forEach(function (error) {
              var errorMessage = _this4.get('notify').alert(error.get('message'), { closeAfter: 5000 });
              _this4.get('errorMessages').addObject(errorMessage);
            });
            this.set('saveDisabled', false);
          }
        } else {
          this.get('notify').alert("Combo selections must contain at least 1 product", { closeAfter: 5000 });
        }
      },
      deleteProduct: function deleteProduct(item) {
        var _this5 = this;

        console.log('deleteProduct', item);

        if (item.get('productCatalogTemplate')) {
          // meta product catalog
          item = this.get('store').findRecord('metaProductCatalog', item.get('id'));
        } else {
          // product catalog
          item = this.get('store').findRecord('productCatalog', item.get('id'));
        }

        var sendRequest = function sendRequest(content) {
          return content.destroyRecord().then(function () {
            _this5.send('cleanMessages');
            _this5.get('notify').success(_this5.get('intl').t('productCatalog.list.errors.deleteSuccess'));
            // this.refreshAction();
          })["catch"](function (error) {
            var message = 'productCatalog.list.errors.deletingError';
            if (error !== undefined) {
              message = "productCatalog.list.errors." + error.errors.get(0).detail;
            }
            var errorMessage = _this5.get('notify').alert(_this5.get('intl').t(message, { name: item.get('name') }), { closeAfter: 5000 });
            _this5.get('errorMessages').addObject(errorMessage);
            content.rollbackAttributes();
          })["finally"](function () {
            jQuery('#j-table-filter-button').trigger('click');
          });
        };

        if (item.then) {
          item.then(function (content) {
            return sendRequest(content);
          });
        } else {
          sendRequest(item);
        }
      },
      addMetaProductCatalog: function addMetaProductCatalog() {
        this.set('saveDisabled', false);
        var companyId = undefined;
        if (this.get('isBRUser')) {
          if (this.get('lockedCompany') !== undefined) {
            companyId = this.get('lockedCompany.id');
          }
        } else {
          companyId = this.get('loggedAccount.company.id');
        }

        this.set('currentMetaProductCatalog', this.get('store').createRecord('metaProductCatalog', {
          companyId: companyId
        }));
        this.set('showMetaProductCatalogModal', true);
      },
      refreshAction: function refreshAction() {
        this.set('showMetaProductCatalogModal', false);
        this.sendAction('refreshAction');
      }
    }
  });
});
/* eslint-disable no-undef */