define('fcs-dashboard/transforms/arraybuffer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return typeof serialized === 'string' ? new TextEncoder().encode(serialized) : serialized;
    },

    serialize: function serialize(deserialized) {
      return deserialized instanceof ArrayBuffer ? new TextDecoder().decode(deserialized) : deserialized;
    }
  });
});