define("fcs-dashboard/pods/components/location-group/custom-fee-modal/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "ember-simple-auth/mixins/authenticated-route-mixin", "fcs-dashboard/utils/util"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _fcsDashboardUtilsUtil) {
  var Component = _ember["default"].Component;
  var isEmpty = _ember["default"].isEmpty;
  var run = _ember["default"].run;
  var computed = _ember["default"].computed;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    enableLogs: computed(function () {
      return this.get('customFeeId') !== null && this.get('customFeeId') !== undefined;
    }),
    showCustomFeeLogsModal: false,
    disabled: false,
    selectedPaymentTypes: [],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      //initialize customFeeTypes
      if (this.get('customFeeTypes').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('customFeeTypes').then(function (content) {
          run(function () {
            _this.set('customFeeTypes', content);
          });
        });
      }
      //initialize customFeeAppliesTos
      if (this.get('customFeeAppliesTos').then !== undefined) {
        // if a promise set the content when it resolves
        this.get('customFeeAppliesTos').then(function (content) {
          run(function () {
            _this.set('customFeeAppliesTos', content);
          });
        });
      }

      this.initCustomPaymentType();
    },
    initCustomPaymentType: function initCustomPaymentType() {
      var _this2 = this;

      if (this.get('customFee.paymentType')) {
        (function () {
          var paymentTypes = _this2.get('customFee.paymentType');
          var paymentTypesArr = paymentTypes.split(',');
          var customPaymentTypes = _this2.get('customPaymentTypes');
          var selectedCustomPaymentTypes = customPaymentTypes.filter(function (item) {
            return paymentTypesArr.includes(item.get('id'));
          });
          _this2.set('selectedPaymentTypes', selectedCustomPaymentTypes);
        })();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('customFee', null);
    },
    checkCustomFee: function checkCustomFee(customFee) {
      var valid = true;

      if (isEmpty(customFee.get('name'))) {
        valid = false;
        this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.emptyName'), { closeAfter: 5000 });
      }

      if (customFee.get('type.id') === null || customFee.get('type.id') === undefined) {
        valid = false;
        this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullType'), { closeAfter: 5000 });
      }

      if (customFee.get('value') === null || customFee.get('value') === undefined || isEmpty(customFee.get('value'))) {
        valid = false;
        this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullValue'), { closeAfter: 5000 });
      }

      if (this.get('selectedPaymentTypes').length === 0) {
        valid = false;
        this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullPaymentType'), { closeAfter: 5000 });
      }

      if (customFee.get('appliesTo.id') === null || customFee.get('appliesTo.id') === undefined) {
        valid = false;
        this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.nullAppliesTo'), { closeAfter: 5000 });
      }

      return valid;
    },
    actions: {
      addCustomFee: function addCustomFee(customFee) {
        var valid = this.checkCustomFee(customFee);
        if (customFee.get('id') === null) {
          customFee.set('tempId', (0, _fcsDashboardUtilsUtil.generateRandomString)(6));
        }
        if (valid) {
          this.send('setCustomFeePaymentType', customFee);
          this.sendAction('addCustomFee', customFee);
        }
      },

      editCustomFee: function editCustomFee(customFee) {
        var valid = this.checkCustomFee(customFee);
        if (valid) {
          this.send('setCustomFeePaymentType', customFee);
          this.sendAction('editCustomFee', customFee);
        }
      },
      setCustomFeePaymentType: function setCustomFeePaymentType(customFee) {
        var paymentType = this.get('selectedPaymentTypes').map(function (item) {
          return item.get('id');
        }).join(',');
        customFee.set('paymentType', paymentType);
      },
      cancelCustomFeeModal: function cancelCustomFeeModal() {
        this.sendAction('cancelCustomFeeModal');
      },
      changeCustomFeeType: function changeCustomFeeType(customFeeType) {
        this.set('customFee.value', 0);
        this.set('customFee.type', customFeeType);
      },
      changeCustomPaymentType: function changeCustomPaymentType(paymentType) {
        this.set('customFee.paymentType', paymentType);
      },
      changeCustomFeeAppliesTo: function changeCustomFeeAppliesTo(appliesTo) {
        this.set('customFee.appliesTo', appliesTo);
      },
      openCustomFeeLogModal: function openCustomFeeLogModal() {
        this.set('showCustomFeeLogsModal', true);
      },
      cancelCustomFeeLogModal: function cancelCustomFeeLogModal() {
        this.set('showCustomFeeLogsModal', false);
      }
    }
  });
});