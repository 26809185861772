define("fcs-dashboard/pods/components/location-group/details/component", ["exports", "ember", "fcs-dashboard/mixins/base-component", "ember-simple-auth/mixins/authenticated-route-mixin", "moment", "fcs-dashboard/config/environment"], function (exports, _ember, _fcsDashboardMixinsBaseComponent, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _moment, _fcsDashboardConfigEnvironment) {
  var Component = _ember["default"].Component;
  var computed = _ember["default"].computed;
  var htmlSafe = _ember["default"].String.htmlSafe;
  var Logger = _ember["default"].Logger;
  var isEqual = _ember["default"].isEqual;
  var run = _ember["default"].run;
  exports["default"] = Component.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin["default"], _fcsDashboardMixinsBaseComponent["default"], {
    companiesLoaded: false,
    companyRolesLoaded: false,
    showDeleteCustomFeeModal: false,
    selectedGateway: null,
    locationGroupCompanies: [],
    reportsToCompanies: [],
    needRefreshPromotionsTable: true,
    _isV4Processor: false,
    tagName: '',
    _timeZones: computed(function () {
      var timeZones = _moment["default"].tz.names();
      var filteredTimezones = [];
      var reg = new RegExp('(?:.*[/].*)');
      timeZones.forEach(function (timezone) {
        if (reg.test(timezone)) {
          filteredTimezones.push(timezone);
        }
      });
      return filteredTimezones.sort();
    }),
    filteredCustomFees: [],
    _locationTypes: computed(function () {
      return this.get('store').findAll('locationType');
    }),
    _customFeeAppliesTos: computed(function () {
      return this.get('store').findAll('customFeeAppliesTo');
    }),
    _customFeeTypes: computed(function () {
      return this.get('store').findAll('customFeeType');
    }),
    _customPaymentTypes: computed(function () {
      return this.get('store').findAll('customPaymentType');
    }),
    _promotionTypes: computed(function () {
      return this.get('store').findAll('promotionType');
    }),
    _promotionRedemptionRules: computed(function () {
      return this.get('store').findAll('promotionRedemptionRule');
    }),
    _promotionStatuses: computed(function () {
      return this.get('store').findAll('promotionStatus');
    }),
    _promotionTriggerTypes: computed(function () {
      return this.get('store').findAll('promotionTriggerType');
    }),
    _creditCards: computed(function () {
      return this.get('store').findAll('creditCardType');
    }),
    _promotionColumns: computed(function () {
      return [{
        title: '',
        template: "promotion/actions/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.name')
      }, {
        propertyName: 'status.name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.status')
      }, {
        propertyName: 'type.name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.type')
      }, {
        propertyName: 'promotionRedemptionRule.name',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.promotionRedemptionRule')
      }, {
        propertyName: 'receiptDisplay',
        title: this.get('intl').t('locationGroups.details.promotions.list.table.receiptDisplay')
      }, {
        title: this.get('intl').t('locationGroups.details.promotions.list.table.active'),
        template: "promotion/actions/activate-action",
        className: 'editRow'
      }];
    }),
    _customFeeColumns: computed(function () {
      return [{
        title: '',
        template: "custom-fee/actions/edit-action",
        className: 'editRow'
      }, {
        propertyName: 'name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.name')
      }, {
        propertyName: 'type.name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.type')
      }, {
        propertyName: 'value',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.value')
      }, {
        propertyName: 'appliesTo.name',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.appliesTo')
      }, {
        propertyName: 'taxable',
        title: this.get('intl').t('locationGroups.details.customFees.list.table.taxable'),
        template: "custom-fee/actions/taxable-action",
        className: 'editRow'
      }, {
        title: this.get('intl').t('locationGroups.details.customFees.list.table.enable'),
        template: "custom-fee/actions/enable-action",
        className: 'editRow'
      }, {
        template: "custom-fee/actions/delete-action",
        className: 'editRow'
      }];
    }),
    _typeOptions: computed(function () {
      return this.get('store').peekAll('locationGroupType');
    }),
    loadReady: computed('companiesLoaded', 'companyRolesLoaded', function () {
      return this.get('companiesLoaded') && this.get('companyRolesLoaded');
    }),
    _gateways: computed(function () {
      return this.get('store').query('gateway', { pageSize: -1 });
    }),
    logoName: computed('locationGroup.logo', function () {
      return this.get('locationGroup.logo') ? 'logo' : null;
    }),
    cashlessLogoName: computed('locationGroup.cashlessInformation.logo', function () {
      return this.get('locationGroup.cashlessInformation.logo') ? 'cashlessLogo' : null;
    }),
    cashlessBackgroundImageName: computed('locationGroup.cashlessInformation.backgroundImage', function () {
      return this.get('locationGroup.cashlessInformation.backgroundImage') ? 'cashlessBackgroundImage' : null;
    }),
    cashlessScreenBrandName: computed('locationGroup.cashlessScreenBrand', function () {
      return this.get('locationGroup.cashlessScreenBrand') ? 'cashlessScreenBrand' : null;
    }),
    tipScreenBrandName: computed('locationGroup.tipScreenBrand', function () {
      return this.get('locationGroup.tipScreenBrand') ? 'tipScreenBrand' : null;
    }),
    thankYouScreenBrandName: computed('locationGroup.thankYouScreenBrand', function () {
      return this.get('locationGroup.thankYouScreenBrand') ? 'thankYouScreenBrand' : null;
    }),
    _isEvent: computed('locationGroup.locationGroupType', function () {
      return isEqual(this.get('locationGroup.locationGroupType.name'), 'Event');
    }),
    logoPreview: computed('locationGroup.logo', function () {
      var url = this.get('locationGroup.logo');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    cashlessLogoPreview: computed('locationGroup.cashlessInformation.logo', function () {
      var url = this.get('locationGroup.cashlessInformation.logo');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    cashlessBackgroundImagePreview: computed('locationGroup.cashlessInformation.backgroundImage', function () {
      var url = this.get('locationGroup.cashlessInformation.backgroundImage');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    cashlessScreenBrandPreview: computed('locationGroup.cashlessScreenBrand', function () {
      var url = this.get('locationGroup.cashlessScreenBrand');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    tipScreenBrandPreview: computed('locationGroup.tipScreenBrand', function () {
      var url = this.get('locationGroup.tipScreenBrand');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    thankYouScreenBrandPreview: computed('locationGroup.thankYouScreenBrand', function () {
      var url = this.get('locationGroup.thankYouScreenBrand');
      return htmlSafe("background-image: url(\"" + url + "\")");
    }),
    _showModal: false,
    _showEndDate: computed('locationGroup.startDate', function () {
      return this.get('locationGroup.startDate') !== null;
    }),
    companies: computed(function () {
      return this.get('store').query('company', { pageSize: -1 });
    }),
    roles: computed(function () {
      return this.get('store').findAll('roleType');
    }),
    minStartDate: computed(function () {
      return new Date();
    }),
    _selectedLocationGroupType: computed.alias('locationGroup.locationGroupType'),
    receiptTextObserver: _ember["default"].observer('locationGroup.receiptText', function () {
      var emojiRegex = /[\uD83C\uD83D\uD83E][\uDC00-\uDFFF]|\uD83C[\uDC04\uDCCF\uDFFF]|\uD83D[\uDC00-\uDDFF]|\uD83E[\uDD00-\uDDFF]|[\u2600-\u26FF\u2700-\u27BF\u2B50]/;
      var value = this.get('locationGroup.receiptText');
      if (emojiRegex.test(value)) {
        value = value.replace(emojiRegex, '');
        this.set('locationGroup.receiptText', value);
      }
      return true;
    }),
    uploading: false,
    wristbandAttachments: [],
    hasWristbandAttachments: computed('wristbandAttachments.@each', function () {
      return this.get('wristbandAttachments.length') > 0;
    }),
    humanReadableCodeLength: computed('locationGroup.cashlessInformation.humanReadableCodeLength', {
      get: function get() {
        return this.get('locationGroup.cashlessInformation.humanReadableCodeLength') || 8;
      },
      set: function set(_, value) {
        this.set('locationGroup.cashlessInformation.humanReadableCodeLength', value);
        return value;
      }
    }),
    cashlessSubDomain: computed('locationGroup.cashlessInformation.eventUrl', {
      get: function get() {
        try {
          var url = new URL(this.get('locationGroup.cashlessInformation.eventUrl'));
          var host = url.hostname;
          var parts = host.split('.');
          return parts.length > 2 ? parts.slice(0, -2).join('.') : '';
        } catch (e) {
          // console.error('Event url is invalid: ', e);
          return '';
        }
      },
      set: function set(_, value) {
        this.set('locationGroup.cashlessInformation.eventUrl', 'https://' + value + _fcsDashboardConfigEnvironment["default"].APP.registrationDomain);
        return value;
      }
    }),
    cashlessDomain: computed(function () {
      return _fcsDashboardConfigEnvironment["default"].APP.registrationDomain;
    }),
    importedWristband: computed('locationGroup.wristbandAttachments', function () {
      return this.get('locationGroup.wristbandAttachments');
    }),
    hasImportedWristbandAttachments: computed('locationGroup.wristbandAttachments.@each', function () {
      return this.get('locationGroup.wristbandAttachments.length') > 0;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('locationGroup.startDate') !== null) {
        this.set('locationGroup.startDate', new Date(_moment["default"].tz(this.get('locationGroup.startDate'), "UTC").format('MM/DD/YYYY')));
      }
      if (this.get('locationGroup.endDate') !== null) {
        this.set('locationGroup.endDate', new Date(_moment["default"].tz(this.get('locationGroup.endDate'), "UTC").format('MM/DD/YYYY')));
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('wristbandAttachments', []);
      var promos = [];
      this.get('locationGroup.promotions').forEach(function (promotion) {
        if (promotion.get('id') !== null && promotion.get('id') !== undefined) {
          promos.addObject(promotion);
        }
      });
      this.set('locationGroup.promotions', promos.sortBy('name'));
      this.get('reportsToCompanies').clear();
      this._initializeLocationTypes();
      this._initializeCompanies();
      this._initializeCompanyRoles();
      this._initializePromotionTypes();
      this._initializePromotionStatuses();
      this._initializeCustomFeeAppliesTos();
      this._initializeCustomFeeTypes();
      this._initializeCustomPaymentTypes();
      this._initializePromotionRules();
      this._initializeCreditCardTypes();
      this._initializePromotionTriggerTypes();
      this.get('filteredCustomFees').clear();
      this.get('locationGroup.customFees').forEach(function (customFee) {
        if (customFee.get('id') !== null) {
          _this.get('filteredCustomFees').addObject(customFee);
        } else {
          _this.get('locationGroup.customFees').removeObject(customFee);
        }
      });
      this.set('refreshCustomFees', true);
      var selectedGateway = undefined;
      if (!_ember["default"].isEmpty(this.get('locationGroup.cashlessInformation.gateway')) && this.get('locationGroup.cashlessInformation.gateway') !== 0) {
        selectedGateway = this.get('store').findRecord('gateway', this.get('locationGroup.cashlessInformation.gateway'));
      }
      if (selectedGateway !== undefined && selectedGateway.then !== undefined) {
        selectedGateway.then(function (content) {
          run(function () {
            _this.set('selectedGateway', content);
            if (_this.get('selectedGateway.processor').then !== undefined) {
              _this.get('selectedGateway.processor').then(function (content) {
                run(function () {
                  if (content !== null && content.name === "FGT") {
                    _this.set('_isV4Processor', false);
                  } else {
                    _this.set('_isV4Processor', true);
                  }
                });
              });
            }
          });
        });
      }
    },
    _initializeLocationTypes: function _initializeLocationTypes() {
      var _this2 = this;

      Logger.debug('Loading Location Types');
      if (this.get('_locationTypes').then !== undefined) {
        this.get('_locationTypes').then(function (content) {
          run(function () {
            Logger.debug('Loading Location Types');
            _this2.set('_locationTypes', content);
          });
        });
      }
    },
    _filterCustomFees: function _filterCustomFees() {
      this.set('refreshCustomFees', false);
      this.get('filteredCustomFees').clear();
      this.get('filteredCustomFees').addObjects(this.get('locationGroup.customFees').filterBy('deleted', false));
      this.set('refreshCustomFees', true);
    },
    _initializeCompanies: function _initializeCompanies() {
      var _this3 = this;

      Logger.debug('Loading Companies for Company Roles');
      if (this.get('companies').then !== undefined) {
        this.get('companies').then(function (content) {
          run(function () {
            Logger.debug('Loaded Companies for Company Roles');
            if (!_this3.isDestroyed && !_this3.isDestroying) {
              _this3.set('companies', content);
              content.forEach(function (company) {
                _this3.get('reportsToCompanies').addObject({ id: company.id, name: company.get('name') });
              });
              _this3.set('companiesLoaded', true);
            }
          });
        });
      }
    },
    _initializeCompanyRoles: function _initializeCompanyRoles() {
      var _this4 = this;

      Logger.debug('Loading Company Roles');
      if (this.get('roles').then !== undefined) {
        this.get('roles').then(function (content) {
          run(function () {
            Logger.debug('Loaded Company Roles');
            if (!_this4.isDestroyed && !_this4.isDestroying) {
              _this4.set('roles', content);
              _this4.set('companyRolesLoaded', true);
            }
          });
        });
      }
    },
    _initializePromotionTypes: function _initializePromotionTypes() {
      var _this5 = this;

      Logger.debug('Loading Promotion Types');
      if (this.get('_promotionTypes').then !== undefined) {
        this.get('_promotionTypes').then(function (content) {
          run(function () {
            if (!_this5.isDestroyed && !_this5.isDestroying) {
              Logger.debug('Loading Promotion Types');
              _this5.set('_promotionTypes', content);
            }
          });
        });
      }
    },
    _initializePromotionTriggerTypes: function _initializePromotionTriggerTypes() {
      var _this6 = this;

      Logger.debug('Loading Promotion Trigger Types');
      if (this.get('_promotionTriggerTypes').then !== undefined) {
        this.get('_promotionTriggerTypes').then(function (content) {
          run(function () {
            if (!_this6.isDestroyed && !_this6.isDestroying) {
              Logger.debug('Loading Promotion Trigger Types');
              _this6.set('_promotionTriggerTypes', content);
            }
          });
        });
      }
    },
    _initializeCustomFeeAppliesTos: function _initializeCustomFeeAppliesTos() {
      var _this7 = this;

      Logger.debug('Loading Custom Fee Applies Tos');
      if (this.get('_customFeeAppliesTos').then !== undefined) {
        this.get('_customFeeAppliesTos').then(function (content) {
          run(function () {
            if (!_this7.isDestroyed && !_this7.isDestroying) {
              Logger.debug('Loading Custom Fee Applies Tos');
              _this7.set('_customFeeAppliesTos', content);
            }
          });
        });
      }
    },
    _initializeCustomFeeTypes: function _initializeCustomFeeTypes() {
      var _this8 = this;

      Logger.debug('Loading Custom Fee Types');
      if (this.get('_customFeeTypes').then !== undefined) {
        this.get('_customFeeTypes').then(function (content) {
          run(function () {
            if (!_this8.isDestroyed && !_this8.isDestroying) {
              Logger.debug('Loading Custom Fee Types');
              _this8.set('_customFeeTypes', content);
            }
          });
        });
      }
    },
    _initializeCustomPaymentTypes: function _initializeCustomPaymentTypes() {
      var _this9 = this;

      Logger.debug('Loading Custom Payment Types');
      if (this.get('_customPaymentTypes').then !== undefined) {
        this.get('_customPaymentTypes').then(function (content) {
          run(function () {
            if (!_this9.isDestroyed && !_this9.isDestroying) {
              _this9.set('_customPaymentTypes', content.sortBy("id"));
            }
          });
        });
      }
    },
    _initializeCreditCardTypes: function _initializeCreditCardTypes() {
      var _this10 = this;

      Logger.debug('Loading Credit Card Types');
      if (this.get('_creditCards').then !== undefined) {
        this.get('_creditCards').then(function (content) {
          run(function () {
            if (!_this10.isDestroyed && !_this10.isDestroying) {
              Logger.debug('Loading Credit Card Types');
              _this10.set('_creditCards', content);
            }
          });
        });
      }
    },
    _initializePromotionRules: function _initializePromotionRules() {
      var _this11 = this;

      Logger.debug('Loading Promotion Rules');
      if (this.get('_promotionRedemptionRules').then !== undefined) {
        this.get('_promotionRedemptionRules').then(function (content) {
          run(function () {
            if (!_this11.isDestroyed && !_this11.isDestroying) {
              Logger.debug('Loading Promotion Types');
              _this11.set('_promotionRedemptionRules', content);
            }
          });
        });
      }
    },
    _initializePromotionStatuses: function _initializePromotionStatuses() {
      var _this12 = this;

      Logger.debug('Loading Promotion Statuses');
      if (this.get('_promotionStatuses').then !== undefined) {
        this.get('_promotionStatuses').then(function (content) {
          run(function () {
            if (!_this12.isDestroyed && !_this12.isDestroying) {
              Logger.debug('Loading Promotion Statuses');
              _this12.set('_promotionStatuses', content);
            }
          });
        });
      }
    },
    actions: {
      save: function save(item) {
        if (this.get("uploading")) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.cashless.wristband.errors.wristbandUploading'), { closeAfter: 5000 });
          return;
        }
        var subDomain = this.get('cashlessSubDomain');
        var isRegistrationSiteEnabled = this.get('locationGroup.cashlessInformation.registrationSiteEnabled');
        if (isRegistrationSiteEnabled && subDomain.trim() === '') {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.errors.eventUrlError'), { closeAfter: 5000 });
          return;
        }
        if (isRegistrationSiteEnabled) {
          var humanReadableCodeLength = this.get('locationGroup.cashlessInformation.humanReadableCodeLength');
          if (humanReadableCodeLength === null || humanReadableCodeLength === undefined) {
            this.set('locationGroup.cashlessInformation.humanReadableCodeLength', 8);
          } else {
            var codeLength = parseInt(humanReadableCodeLength);
            if (isNaN(humanReadableCodeLength) || isNaN(codeLength) || codeLength <= 0) {
              this.get('notify').alert(this.get('intl').t('locationGroups.details.cashless.humanReadableCodeLength.error'), { closeAfter: 5000 });
              return;
            }
          }
        }
        this.set('saveEnabled', false);
        this.set('locationGroup.enableBannerBranding', true);
        this.set('locationGroup.wristbandFiles', JSON.stringify(this.get('wristbandAttachments')));
        this.sendAction('save', item);
      },
      cancel: function cancel(item) {
        this.sendAction('cancel', item);
      },
      uploadLogo: function uploadLogo(fileList) {
        var _this13 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this13;
            _this13.set('logoName', fileList[0].name);
            var reader = new FileReader();

            reader.onloadend = function () {
              that.set('locationGroup.logo', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },
      uploadCashlessLogo: function uploadCashlessLogo(fileList) {
        var _this14 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this14;
            _this14.set('cashlessLogoName', fileList[0].name);
            var reader = new FileReader();
            reader.onloadend = function () {
              that.set('locationGroup.cashlessInformation.logo', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },

      uploadCashlessBackgroundImage: function uploadCashlessBackgroundImage(fileList) {
        var _this15 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this15;
            _this15.set('cashlessBackgroundImageName', fileList[0].name);
            var reader = new FileReader();
            reader.onloadend = function () {
              that.set('locationGroup.cashlessInformation.backgroundImage', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },
      uploadWristbandFiles: function uploadWristbandFiles(fileList) {
        var _this16 = this;

        var re = new RegExp('\\.xls[x]?$', 'i');
        var _authToken = this.get('session.data.authenticated.access_token');
        var form = new FormData();
        var self = this;

        var _loop = function (i) {
          if (!re.test(fileList[i].name.toLowerCase())) {
            _this16.get('notify').alert(_this16.get('intl').t('locationGroups.details.cashless.wristband.errors.invalidFileType'), { closeAfter: 5000 });
            return {
              v: undefined
            };
          } else {
            if (_this16.get('wristbandAttachments').filter(function (item) {
              return item.name === fileList[i].name;
            }).length > 0) {
              _this16.get('notify').alert(_this16.get('intl').t('locationGroups.details.cashless.wristband.errors.fileExists'), { closeAfter: 5000 });
              return {
                v: undefined
              };
            }
            form.append("files", fileList[i]);
          }
        };

        for (var i = 0; i < fileList.length; i++) {
          var _ret4 = _loop(i);

          if (typeof _ret4 === "object") return _ret4.v;
        }

        var settings = {
          url: "/api/v1/locationGroups/uploadWristband",
          method: "POST",
          mimeType: "multipart/form-data",
          headers: {
            "Authorization": "Bearer " + _authToken
          },
          processData: false,
          contentType: false,
          data: form,
          dataType: 'json'
        };
        self.set('uploading', true);
        $.ajax(settings).done(function (response) {
          if (response.result) {
            response.result.forEach(function (item) {
              self.get('wristbandAttachments').pushObject({ name: item.name, key: item.key });
            });
          }
          self.set('uploading', false);
        });
      },

      removeWristbandFile: function removeWristbandFile(file) {
        var data = this.get('wristbandAttachments').filter(function (item) {
          return item.name !== file.name;
        });
        this.set('wristbandAttachments', data);
      },

      previewWristbandFile: function previewWristbandFile(file) {
        this.set('previewWristbandFile', file);
      },

      uploadCashlessScreenBrand: function uploadCashlessScreenBrand(fileList) {
        var _this17 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this17;
            _this17.set('cashlessScreenBrandName', fileList[0].name);
            _this17.set('locationGroup.enableBannerBranding', true);
            var reader = new FileReader();
            reader.onloadend = function () {
              that.set('locationGroup.cashlessScreenBrand', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },

      uploadTipScreenBrand: function uploadTipScreenBrand(fileList) {
        var _this18 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this18;
            _this18.set('tipScreenBrandName', fileList[0].name);
            _this18.set('locationGroup.enableBannerBranding', true);
            var reader = new FileReader();

            reader.onloadend = function () {
              that.set('locationGroup.tipScreenBrand', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },

      uploadThankYouScreenBrand: function uploadThankYouScreenBrand(fileList) {
        var _this19 = this;

        var re = new RegExp('image/*');
        if (re.test(fileList[0].type)) {
          (function () {
            var that = _this19;
            _this19.set('thankYouScreenBrandName', fileList[0].name);
            _this19.set('locationGroup.enableBannerBranding', true);
            var reader = new FileReader();

            reader.onloadend = function () {
              that.set('locationGroup.thankYouScreenBrand', reader.result);
            };

            reader.readAsDataURL(fileList[0]);
          })();
        } else {
          Logger.debug('not an image.');
        }
      },
      openModal: function openModal() {
        this.set('showModal', true);
      },
      closeModal: function closeModal() {
        Logger.debug("Closing modal. Timezone staid as it was " + this.get('locationGroup.timezone'));
        this.set('showModal', false);
      },
      saveTimezoneAction: function saveTimezoneAction(timezone) {
        Logger.debug("Timezone received from modal was " + timezone);
        this.set('locationGroup.timezone', timezone);
        Logger.debug("Timezone set into the model as " + this.get('locationGroup.timezone'));
        this.set('showModal', false);
      },
      changeTimeZone: function changeTimeZone(timezone) {
        this.set('locationGroup.timezone', timezone);
      },
      addCompany: function addCompany() {
        var company = this.get('store').createFragment('companyRole');
        this.get('locationGroup.companiesRoles').addObject(company);
      },
      removeItem: function removeItem(item) {
        this.get('locationGroup.companiesRoles').removeObject(item);
      },
      changeStartDate: function changeStartDate(value) {
        var _this20 = this;

        this.set('locationGroup.startDate', value);
        if ((0, _moment["default"])(value).isAfter(this.get('locationGroup.endDate'))) {
          _ember["default"].run.next(function () {
            _this20.set('locationGroup.endDate', null);
          });
        }
      },
      changeEndDate: function changeEndDate(value) {
        var _this21 = this;

        _ember["default"].run.next(function () {
          _this21.set('locationGroup.endDate', value);
        });
      },
      removeLogo: function removeLogo() {
        this.set('logoName', null);
        this.set('locationGroup.logo', null);
      },
      removeCashlessLogo: function removeCashlessLogo() {
        this.set('cashlessLogoName', null);
        this.set('locationGroup.cashlessInformation.logo', null);
      },
      removeCashlessBackgroundImage: function removeCashlessBackgroundImage() {
        this.set('cashlessBackgroundImageName', null);
        this.set('locationGroup.cashlessInformation.backgroundImage', null);
      },
      removeCashlessScreenBrand: function removeCashlessScreenBrand() {
        this.set('cashlessScreenBrandName', null);
        this.set('locationGroup.cashlessScreenBrand', null);
      },
      removeTipScreenBrand: function removeTipScreenBrand() {
        this.set('tipScreenBrandName', null);
        this.set('locationGroup.tipScreenBrand', null);
      },
      removeThankYouScreenBrand: function removeThankYouScreenBrand() {
        this.set('thankYouScreenBrandName', null);
        this.set('locationGroup.thankYouScreenBrand', null);
      },
      changeLocationGroupType: function changeLocationGroupType(type) {
        if (type.data.name === 'Venue') {
          this.set('_isEvent', false);
        } else {
          this.set('_isEvent', true);
        }
        this.set('_selectedLocationGroupType', type);
      },
      changeSelectedGateway: function changeSelectedGateway(gateway) {
        this.set('selectedGateway', gateway);
        this.set('locationGroup.cashlessInformation.gateway', gateway.id);
        if (this.get('selectedGateway.processor.name') === "FGT") {
          this.set('_isV4Processor', false);
        } else {
          this.set('_isV4Processor', true);
        }
      },
      inactivatePromotion: function inactivatePromotion(promotion) {
        promotion.set('status', this.get('store').peekRecord('promotionStatus', 2));
      },
      activatePromotion: function activatePromotion(promotion) {
        promotion.set('status', this.get('store').peekRecord('promotionStatus', 1));
      },
      addPromotion: function addPromotion(promotion) {
        var _this22 = this;

        var filteredPromotionsByNames = this.get('locationGroup.promotions').filterBy('name', promotion.get('name'));
        if (filteredPromotionsByNames.length > 0) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          this.get('locationGroup.promotions').addObject(promotion);
          this.set('locationGroup.promotions', this.get('locationGroup.promotions').sortBy('name'));
          this.set('needRefreshPagesTable', false);
          run.next(function () {
            _this22.set('showPromotionModal', false);
            _this22.set('needRefreshPagesTable', true);
          });
        }
      },
      editPromotion: function editPromotion(promotion) {
        var _this23 = this;

        var filteredPromotionsByNames = this.get('locationGroup.promotions').filterBy('name', promotion.get('name'));
        if (filteredPromotionsByNames.length > 0 && filteredPromotionsByNames.get('firstObject').get('id') !== this.get('promotionId')) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.promotions.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          // update promotion values with new ones
          var oldPromo = this.get('locationGroup.promotions').filterBy('id', this.get('promotionId')).get('firstObject');
          oldPromo.set('name', promotion.get('name'));
          oldPromo.set('status', promotion.get('status'));
          oldPromo.set('amount', promotion.get('amount'));
          oldPromo.set('type', promotion.get('type'));
          oldPromo.set('promotionRedemptionRule', promotion.get('promotionRedemptionRule'));
          oldPromo.set('productCatalogs', promotion.get('productCatalogs'));
          oldPromo.set('quantity', promotion.get('quantity'));
          oldPromo.set('receiptDisplayInline', promotion.get('receiptDisplayInline'));
          oldPromo.set('redemptionMaxCount', promotion.get('redemptionMaxCount'));
          oldPromo.set('redemptionExpirationDateTime', promotion.get('redemptionExpirationDateTime'));
          oldPromo.set('cardType', promotion.get('cardType'));
          oldPromo.set('triggerType', promotion.get('triggerType'));
          oldPromo.set('images', promotion.get('images'));
          oldPromo.set('binCsv', promotion.get('binCsv'));
          oldPromo.set('minimumSpentTotal', promotion.get('minimumSpentTotal'));
          oldPromo.set('binCount', promotion.get('binCount'));

          promotion.get('images').clear();
          this.set('needRefreshPagesTable', false);
          run.next(function () {
            _this23.set('showPromotionModal', false);
            _this23.set('needRefreshPagesTable', true);
          });
        }
      },
      openPromotionModal: function openPromotionModal() {
        this.set('locationGroupCompanies', this.get('locationGroup.companiesRoles').mapBy('companyId'));
        this.set('editing', false);
        var promotion = this.get('store').createRecord('promotion');
        promotion.set('status', this.get('store').peekRecord('promotionStatus', 0));
        this.set('promotion', promotion);
        this.set('showPromotionModal', true);
      },
      editPromotionModal: function editPromotionModal(promotion) {
        this.set('locationGroupCompanies', this.get('locationGroup.companiesRoles').mapBy('companyId'));
        this.set('editing', true);
        var tempPromotion = this.get('store').createRecord('promotion', {
          name: promotion.get('name'),
          status: promotion.get('status'),
          amount: promotion.get('amount'),
          type: promotion.get('type'),
          promotionRedemptionRule: promotion.get('promotionRedemptionRule'),
          productCatalogs: promotion.get('productCatalogs'),
          quantity: promotion.get('quantity'),
          receiptDisplayInline: promotion.get('receiptDisplayInline'),
          redemptionMaxCount: promotion.get('redemptionMaxCount'),
          redemptionExpirationDateTime: promotion.get('redemptionExpirationDateTime'),
          triggerType: promotion.get('triggerType'),
          cardType: promotion.get('cardType'),
          images: promotion.get('images'),
          binCsv: promotion.get('binCsv'),
          binCount: promotion.get('binCount'),
          minimumSpentTotal: promotion.get('minimumSpentTotal')
        });
        this.set('promotion', tempPromotion);
        this.set('promotionId', promotion.get('id'));
        this.set('showPromotionModal', true);
      },
      cancelPromotionModal: function cancelPromotionModal() {
        this.set('showPromotionModal', false);
      },
      cancelDeleteCustomFeeModal: function cancelDeleteCustomFeeModal() {
        this.set('showDeleteCustomFeeModal', false);
      },
      closePromotionModal: function closePromotionModal() {
        this.set('showPromotionModal', false);
      },
      disableCustomFee: function disableCustomFee(customFee) {
        customFee.set('changed', true);
        customFee.set('enabled', false);
      },
      enableCustomFee: function enableCustomFee(customFee) {
        customFee.set('changed', true);
        customFee.set('enabled', true);
      },
      openDeleteCustomFeeModal: function openDeleteCustomFeeModal(customFee) {
        this.set('selectedCustomFee', customFee);
        this.set('showDeleteCustomFeeModal', true);
      },
      deleteCustomFee: function deleteCustomFee(customFee) {
        customFee.set('changed', true);
        customFee.set('deleted', true);
        this.set('showDeleteCustomFeeModal', false);
        this.set('selectedCustomFee', null);
        this._filterCustomFees();
      },
      addCustomFee: function addCustomFee(customFee) {
        var _this24 = this;

        var filteredCustomFeesByNames = this.get('filteredCustomFees').filterBy('name', customFee.get('name'));
        if (filteredCustomFeesByNames.length > 0) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          this.get('filteredCustomFees').addObject(customFee);
          this.get('locationGroup.customFees').addObject(customFee);
          this.set('locationGroup.customFees', this.get('locationGroup.customFees').sortBy('name'));
          this.set('refreshCustomFees', false);
          run.next(function () {
            _this24.set('showCustomFeeModal', false);
            _this24.set('refreshCustomFees', true);
          });
        }
      },
      editCustomFee: function editCustomFee(customFee) {
        var _this25 = this;

        var filteredCustomFeesByNames = this.get('filteredCustomFees').filterBy('name', customFee.get('name'));
        if (filteredCustomFeesByNames.length > 0 && (filteredCustomFeesByNames.get('firstObject').get('id') !== this.get('customFeeId') || filteredCustomFeesByNames.get('firstObject').get('tempId') !== this.get('tempId'))) {
          this.get('notify').alert(this.get('intl').t('locationGroups.details.customFees.modal.errors.duplicatedName'), { closeAfter: 5000 });
        } else {
          var oldCustomFee = undefined;
          // update customFee values with new ones
          if (this.get('customFeeId') !== null) {
            oldCustomFee = this.get('locationGroup.customFees').filterBy('id', this.get('customFeeId')).get('firstObject');
          } else {
            oldCustomFee = this.get('locationGroup.customFees').filterBy('tempId', this.get('tempId')).get('firstObject');
          }

          oldCustomFee.set('name', customFee.get('name'));
          oldCustomFee.set('enabled', customFee.get('enabled'));
          oldCustomFee.set('type', customFee.get('type'));
          oldCustomFee.set('appliesTo', customFee.get('appliesTo'));
          oldCustomFee.set('value', customFee.get('value'));
          oldCustomFee.set('deleted', customFee.get('deleted'));
          oldCustomFee.set('taxable', customFee.get('taxable'));
          oldCustomFee.set('changed', customFee.get('changed'));
          oldCustomFee.set('paymentType', customFee.get('paymentType'));
          this.set('needRefreshPagesTable', false);
          run.next(function () {
            _this25.set('showCustomFeeModal', false);
            _this25.set('needRefreshPagesTable', true);
          });
        }
      },
      openCustomFeeModal: function openCustomFeeModal() {
        this.set('editing', false);
        var customFee = this.get('store').createRecord('customFee');
        this.set('customFee', customFee);
        this.set('customFeeId', null);
        this.set('tempId', null);
        this.set('showCustomFeeModal', true);
      },
      editCustomFeeModal: function editCustomFeeModal(customFee) {
        this.set('editing', true);
        var tempCustomFee = this.get('store').createRecord('customFee', {
          name: customFee.get('name'),
          enabled: customFee.get('enabled'),
          appliesTo: customFee.get('appliesTo'),
          type: customFee.get('type'),
          paymentType: customFee.get('paymentType'),
          value: customFee.get('value'),
          taxable: customFee.get('taxable'),
          changed: true,
          tempId: customFee.get('tempId')
        });
        this.set('customFee', tempCustomFee);
        this.set('customFeeId', customFee.get('id'));
        this.set('tempId', customFee.get('tempId'));
        this.set('showCustomFeeModal', true);
      },
      cancelCustomFeeModal: function cancelCustomFeeModal() {
        this.set('showCustomFeeModal', false);
      },
      closeCustomFeeModal: function closeCustomFeeModal() {
        this.set('showCustomFeeModal', false);
      },
      downloadWristbandFile: function downloadWristbandFile(wristbandAttachment) {
        var _this26 = this;

        var locationGroupId = this.get('locationGroup.id');
        var attachmentId = wristbandAttachment.get('attachmentId');
        var filename = wristbandAttachment.get('name');
        var endpoint = '/api/v1/locationGroups/' + locationGroupId + '/wristband_attachments/' + attachmentId;
        var _authToken = this.get('session.data.authenticated.access_token');
        var options = {
          url: endpoint,
          type: 'GET',
          xhrFields: {
            responseType: 'blob'
          }
        };
        options.beforeSend = function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + _authToken);
        };
        options.success = function (result) {
          var url = window.URL.createObjectURL(result);
          var link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(url);
        };
        options.error = function (error) {
          _this26.get('notify').alert(_this26.get('intl').t('locationGroups.details.errors.wristbandAttachmentFileDownloadError'), { closeAfter: 5000 });
          console.log('Error: ' + error.responseText);
        };
        _ember["default"].$.ajax(options);
      }
    }
  });
});